import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { ToastrModule } from 'ngx-toastr'
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler'

import { AppComponent } from './app.component'
import { routes } from './app.routes'
import { ThemeModule } from './modules/theme/theme.module'
import { ApiInterceptor } from './services/api.interceptor'
import { AuthGuard } from './services/auth-guard.service'
import { AuthService } from './services/auth.service'
import {
  CarClassService,
  CarModelService,
  CatalogueService,
  EquipmentEntryService,
  EquipmentGroupService,
  FuelTypeService,
  GearboxService,
  MakeService,
  ModelLineService,
} from './services/car'
import { GeneralSettingsService } from './services/general-settings.service'
import { InitService } from './services/init.service'
import { InsuranceCompanyService } from './services/insurance/insurance-company.service'
import { InsuranceProductService } from './services/insurance/insurance-product.service'
import { InsuranceRateService } from './services/insurance/insurance-rate.service'
import { InsuranceRestrictionService } from './services/insurance/insurance-restriction.service'
import { MakeRestrictionService } from './services/make-restriction.service'
import { OrgUnitService } from './services/org-unit.service'
import { PneuService } from './services/pneu.service'
import { PriceListFuelCardService } from './services/price-list/price-list-fuel-card.service'
import { PriceListRegistrationFeeService } from './services/price-list/price-list-registration-fee.service'
import { PriceListReplacementCarService } from './services/price-list/price-list-replacement-car.service'
import { PriceListRoadTollService } from './services/price-list/price-list-road-toll.service'
import { RoadTaxDiscountService } from './services/price-list/road-tax-discount.service'
import { RoadTaxService } from './services/price-list/road-tax.service'
import { ProductService } from './services/product.service'
import { ProvisionService } from './services/provision.service'
import { RefiService } from './services/refi.service'
import { RoleService } from './services/role.service'
import { ServiceTypeService } from './services/service-type.service'
import { TokenService } from './services/token.service'
import { UserService } from './services/user.service'
import {BaseComponent} from './modules/theme/base/base.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
  ],
  imports: [
    //angular modules
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {}),
    BrowserAnimationsModule,

    //external library
    InlineSVGModule,
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),

    //app modules (not lazy loaded)
    ThemeModule,
  ],
  providers: [
    AuthGuard,
    AuthService,
    TokenService,
    CarModelService,
    CatalogueService,
    CarClassService,
    FuelTypeService,
    GearboxService,
    GeneralSettingsService,
    MakeService,
    ModelLineService,
    PriceListFuelCardService,
    PriceListReplacementCarService,
    PriceListRoadTollService,
    PriceListRegistrationFeeService,
    RoadTaxService,
    RoadTaxDiscountService,
    RefiService,
    EquipmentEntryService,
    EquipmentGroupService,
    UserService,
    OrgUnitService,
    ProductService,
    ProvisionService,
    ServiceTypeService,
    InsuranceCompanyService,
    InsuranceProductService,
    InsuranceRateService,
    InsuranceRestrictionService,
    MakeRestrictionService,
    RoleService,
    InitService,
    PneuService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ts: TokenService) => function() {
        ts.setTokenRefreshTimer()
      },
      deps: [TokenService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      deps: [AuthService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
