import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { UserModel } from '../models'
import { AuthService } from './auth.service'
import { InitService } from './init.service'
import { TokenService } from './token.service'

@Injectable()
export class AuthGuard  {

  private user: UserModel

  constructor(
    private router: Router,
    private _initService: InitService,
    private _authService: AuthService,
    public _tokenService: TokenService) {
    this._authService.user.subscribe((user: UserModel) => {
      this.user = user
    })
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authGuard(state.url, route.data)
  }

  canLoad(route: Route): Observable<boolean> {
    const url = `/${ route.path }`
    return this.authGuard(url, route.data)
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authGuard(state.url, route.data)
  }

  public hasAccess(requiredPermission: string[]): boolean {
    if (this.user && requiredPermission) {
      return this.user.role.claims.some(userClaim => {
        return requiredPermission.some((requiredClaim) => userClaim.name === requiredClaim)
      })
    }
  }

  public hasEditPermission(editPermission: string): boolean {
    if (this._authService.editPermissions && editPermission) {
      return this._authService.editPermissions[editPermission]
    }
  }

  private authGuard(url: string, routeData: any): Observable<boolean> {
    // user logged in
    if (!this._tokenService.isExpired()) {
      if (this.user) {
        return of(this.checkUserPermission(url, routeData))
      } else {
        return this._initService.init()
          .pipe(
            map(() => this.checkUserPermission(url, routeData)),
            catchError(() => {
              return of(this.unauthorized(url), true)
            }),
          )
      }
    }
    // unauthorized
    else {
      this._tokenService.removeToken()
      if (url.startsWith('/public')) {
        this._initService.isLoaded$.next(true)
        return of(true)
      }
      return of(this.unauthorized(url))
    }
  }

  private unauthorized(route: string) {
    if (this.user) {
      this.router.navigate(['/missing-permission'])
    } else {
      this._authService.redirectUrl = route
      this._tokenService.removeToken()
      this.user = null
      this.router.navigate(['/public/login'])
    }
    return false
  }

  private checkUserPermission(route: string, routeData: any) {
    let hasAccess = true
    let hasEditPermission = true
    if (routeData && routeData.requiredPermission) {
      hasAccess = this.hasAccess(routeData.requiredPermission)
    }
    if (routeData && routeData.editPermission) {
      hasEditPermission = this.hasEditPermission(routeData.editPermission)
    }

    if (hasAccess && hasEditPermission) {
      return true
    }
    return this.unauthorized(route)
  }
}
