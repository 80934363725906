import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { GeneralSettingsModel } from '../models/general-settings.model'

@Injectable()
export class GeneralSettingsService {

  public url = '/api/administration/GeneralSettings'
  public settings: GeneralSettingsModel

  constructor(private _http: HttpClient) {
  }

  public getSettings(): Observable<GeneralSettingsModel> {
    return this._http.get(this.url)
      .pipe(
        map(res => {
          this.settings = new GeneralSettingsModel(res)
          return this.settings
        }),
      )
  }

  public save(model: GeneralSettingsModel): Observable<GeneralSettingsModel> {
    return this._http.post(this.url, model)
      .pipe(
        map(res => new GeneralSettingsModel(res)),
      )
  }
}
