import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/index'
import { AuthService } from './auth.service'
import { tokenGetter } from './token.service'

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(public _authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'Authorization'
    const token = tokenGetter()

    if (token && !request.headers.has(headerName)) {
      request = request.clone({
        headers: request.headers.set(headerName, 'Bearer ' + token),
      })
    }

    const noCacheReq = request.clone({
      headers: request.headers
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache'),
    })

    this._authService.resetInactivity.next(true)

    return next.handle(noCacheReq)
  }
}
