import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { FilterModel, ListModel } from '../../models'
import { RoadTaxDiscountModel } from '../../models/price-list/road-tax-discount.model'

@Injectable()
export class RoadTaxDiscountService {

  public url: string
  public filter: BehaviorSubject<FilterModel> = new BehaviorSubject<FilterModel>(new FilterModel())

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/ServiceRoadTaxDiscount'
  }

  public getList(): Observable<ListModel<RoadTaxDiscountModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<RoadTaxDiscountModel>) => {
          const list = new ListModel<RoadTaxDiscountModel>()
          list.total = res.total
          list.data = res.data.map((x) => new RoadTaxDiscountModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<RoadTaxDiscountModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: RoadTaxDiscountModel) => {
          return new RoadTaxDiscountModel(res)
        }),
      )
  }

  public save(item: RoadTaxDiscountModel): Observable<boolean> {
    return this._http.post(this.url, item)
      .pipe(map(() => {
        this._toaster.success(this._translate.instant('messages.created'))
        return true
      }))
  }

  public update(item: RoadTaxDiscountModel): Observable<boolean> {
    return this._http.put(this.url + '/' + item.id, item)
      .pipe(map(() => {
        this._toaster.success(this._translate.instant('messages.updated'))
        return true
      }))
  }

  public delete(roadTollId: number): Observable<boolean> {
    return this._http.delete(this.url + '/' + roadTollId).pipe(
      map(() => {
        this._toaster.success(this._translate.instant('messages.deleted'))
        return true
      }),
    )
  }

}
