import { Component, Input, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { debounceTime, filter } from 'rxjs/internal/operators'
import { takeWhile } from 'rxjs/operators'
import { isNullOrUndefined } from '../../../helpers/util.helper'

import { BaseComponent } from '../base/base.component'

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent extends BaseComponent implements OnInit {

  private static LOADER_DEBOUNCE_TIME = 250
  public isLoaded: Boolean = false

  @Input() isLoaded$: BehaviorSubject<boolean>
  @Input() debounceTime: number
  @Input() size: string

  ngOnInit(): void {
    // this part is responsible for showing SVG loader if and only if request take more then LOADER_DEBOUNCE_TIME
    // so when your requests are very fast, the screen is not blinking
    this.isLoaded$.pipe(
      takeWhile(() => this.alive),
      filter(is => is === true),
    ).subscribe(() => this.isLoaded = true)

    this.isLoaded$.pipe(
      takeWhile(() => this.alive),
      filter(is => is === false),
      debounceTime(isNullOrUndefined(this.debounceTime) ? LoaderComponent.LOADER_DEBOUNCE_TIME : this.debounceTime),
    ).subscribe(() => this.isLoaded = this.isLoaded$.getValue())
  }

}
