import { Pipe, PipeTransform } from '@angular/core'
import { GeneralSettingsService } from '../services/general-settings.service'

@Pipe({
  name: 'appCurrency',
  pure: false,
})
export class AppCurrencyPipe implements PipeTransform {

  constructor(private _general: GeneralSettingsService) {
  }

  transform(value: number): string {
    const transformed = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return transformed + ' ' + this._general.settings.currency // add currency
  }

}
