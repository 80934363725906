import { FuelTypeModel } from '../car.models'

export class RoadTaxDiscountModel {
  id: number
  name: string
  discount: number
  type: RoadTaxDiscountType
  rentDurationFrom: number
  rentDurationTo: number
  fuelType: FuelTypeModel

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.discount = data.discount
      this.type = data.type
      this.rentDurationFrom = data.rentDurationFrom
      this.rentDurationTo = data.rentDurationTo
      this.fuelType = data.fuelType ? new FuelTypeModel(data.fuelType) : null
    }
  }
}

export enum RoadTaxDiscountType {
  RentDuration = 0,
  FuelType = 1
}
