import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { CarModel, CarModelFilterModel, FilterModel, ListModel } from '../../models'
import { CommonDatepickerFunctions } from '../common-datepicker-functions'

@Injectable()
export class CarModelService {

  public url: string
  public filter: BehaviorSubject<CarModelFilterModel>

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/CarModel'
    this.filter = new BehaviorSubject<CarModelFilterModel>(new CarModelFilterModel())
  }

  public getList(): Observable<ListModel<CarModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<CarModel>) => {
          const list = new ListModel<CarModel>()
          list.total = res.total
          res.data.forEach((x) => {
            x.saleFrom = CommonDatepickerFunctions.toNgbDateStruct(x.saleFrom)
            x.saleTo = CommonDatepickerFunctions.toNgbDateStruct(x.saleTo)
          })
          list.data = res.data.map((x) => new CarModel(x))
          return list
        }),
      )
  }

  public getDropdownList(filter: FilterModel): Observable<ListModel<CarModel>> {
    return this._http.get(this.url, { params: filter.toBeFilter() })
      .pipe(
        map((res: ListModel<CarModel>) => {
          const list = new ListModel<CarModel>()
          list.total = res.total
          list.data = res.data.map((x) => new CarModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<CarModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: CarModel) => {
          res.saleFrom = CommonDatepickerFunctions.toNgbDateStruct(res.saleFrom)
          res.saleTo = CommonDatepickerFunctions.toNgbDateStruct(res.saleTo)
          return new CarModel(res)
        }),
      )
  }

  public save(carModel: CarModel): Observable<boolean> {
    const entity: any = carModel
    entity.saleFrom = CommonDatepickerFunctions.getDataDate(carModel.saleFrom)
    entity.saleTo = CommonDatepickerFunctions.getDataDate(carModel.saleTo)

    return this._http.post(this.url, entity)
      .pipe(map(() => {
        this._toaster.success(this._translate.instant('messages.created'))
        return true
      }))
  }

  public update(carModel: CarModel): Observable<boolean> {
    const entity: any = carModel
    entity.saleFrom = CommonDatepickerFunctions.getDataDate(carModel.saleFrom)
    entity.saleTo = CommonDatepickerFunctions.getDataDate(carModel.saleTo)

    return this._http.put(this.url + '/' + carModel.id, carModel)
      .pipe(map(() => {
        this._toaster.success(this._translate.instant('messages.updated'))
        return true
      }))
  }

  public delete(carModelId: number): Observable<boolean> {
    return this._http.delete(this.url + '/' + carModelId).pipe(
      map(() => {
        this._toaster.success(this._translate.instant('messages.deleted'))
        return true
      }))
  }
}
