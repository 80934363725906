import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { ListModel, ProductFilter, ProductModel } from '../models'

@Injectable()
export class ProductService {

  public url: string
  public filter: BehaviorSubject<ProductFilter> = new BehaviorSubject<ProductFilter>(new ProductFilter())

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/product'
  }

  public getList(): Observable<ListModel<ProductModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<ProductModel>) => {
          const list = new ListModel<ProductModel>()
          list.total = res.total
          list.data = res.data.map((x) => new ProductModel(x))
          return list
        }),
      )
  }

  public getDropdownList(filter: ProductFilter): Observable<ListModel<ProductModel>> {
    return this._http.get(this.url, { params: filter.toBeFilter() })
      .pipe(
        map((res: ListModel<ProductModel>) => {
          const list = new ListModel<ProductModel>()
          list.total = res.total
          list.data = res.data.map((x) => new ProductModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<ProductModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: ProductModel) => new ProductModel(res)),
      )
  }

  public save(product: ProductModel): Observable<ProductModel> {
    return this._http.post(this.url, product)
      .pipe(map((res: ProductModel) => {
        this._toaster.success(this._translate.instant('messages.created'))
        return new ProductModel(res)
      }))
  }

  public update(product: ProductModel, productId: Number): Observable<ProductModel> {
    return this._http.put(this.url + '/' + productId, product).pipe(
      map((res: ProductModel) => {
        this._toaster.success(this._translate.instant('messages.updated'))
        return new ProductModel(res)
      }))
  }

  public delete(productId: number): Observable<boolean> {
    return this._http.delete(this.url + '/' + productId).pipe(
      map(() => {
        this._toaster.success(this._translate.instant('messages.deleted'))
        return true
      }))
  }

}
