import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmModalComponent } from '../modules/theme/confirm-modal/confirm-modal.component'

@Directive({
  selector: '[appConfirmDeletion]',
})
export class ConfirmDeletionDirective {

  @Input() item: any
  @Input() title: String = 'messages.confirmDeletionQuestion'
  @Output() afterConfirm = new EventEmitter<number>()

  constructor(private _modalService: NgbModal) {
  }

  @HostListener('click', ['$event']) onClick($event) {
    const modalRef = this._modalService.open(ConfirmModalComponent)
    modalRef.componentInstance.title = this.title
    modalRef.componentInstance.confirmText = 'buttons.delete'
    modalRef.componentInstance.denyText = 'common.no'
    modalRef.result.then(
      result => {
        if (result === 'confirm') {
          this.afterConfirm.emit(this.item)
        }
      },
      () => {
      },
    )
  }

}
