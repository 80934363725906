import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { UserModel } from '../models'
import { GeneralSettingsModel } from '../models/general-settings.model'
import { AuthService } from './auth.service'
import { GeneralSettingsService } from './general-settings.service'

@Injectable()
export class InitService {

  public isLoaded$ = new BehaviorSubject<Boolean>(false)

  constructor(private _http: HttpClient,
              private _auth: AuthService,
              private _general: GeneralSettingsService) {
  }

  public init(): Observable<[UserModel, GeneralSettingsModel]> {
    this.isLoaded$.next(false)
    return combineLatest(this._auth.getUser(), this._general.getSettings())
      .pipe(
        finalize(() => this.isLoaded$.next(true)),
      )
  }
}
