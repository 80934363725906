import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { InsuranceProductFilterModel, InsuranceProductModel, ListModel } from '../../models'

@Injectable()
export class InsuranceProductService {

  public url: string
  public filter: BehaviorSubject<InsuranceProductFilterModel>

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/InsuranceProduct'
    this.filter = new BehaviorSubject<InsuranceProductFilterModel>(new InsuranceProductFilterModel())
  }

  public getList(): Observable<ListModel<InsuranceProductModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<InsuranceProductModel>) => {
          const list = new ListModel<InsuranceProductModel>()
          list.total = res.total
          list.data = res.data.map((x) => new InsuranceProductModel(x))
          return list
        }),
      )
  }

  public getDropdownList(filter: InsuranceProductFilterModel): Observable<ListModel<InsuranceProductModel>> {
    return this._http.get(this.url, { params: filter.toBeFilter() })
      .pipe(
        map((res: ListModel<InsuranceProductModel>) => {
          const list = new ListModel<InsuranceProductModel>()
          list.total = res.total
          list.data = res.data.map((x) => new InsuranceProductModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<InsuranceProductModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: InsuranceProductModel) => new InsuranceProductModel(res)),
      )
  }

  public save(item: InsuranceProductModel): Observable<InsuranceProductModel> {
    return this._http.post(this.url, item)
      .pipe(map((res: InsuranceProductModel) => {
        this._toaster.success(this._translate.instant('messages.created'))
        return new InsuranceProductModel(res)
      }))
  }

  public update(item: InsuranceProductModel): Observable<InsuranceProductModel> {
    return this._http.put(this.url + '/' + item.id, item)
      .pipe(map((res: InsuranceProductModel) => {
        this._toaster.success(this._translate.instant('messages.updated'))
        return new InsuranceProductModel(res)
      }))
  }

  public delete(id: number): Observable<boolean> {
    return this._http.delete(this.url + '/' + id).pipe(map(() => {
      this._toaster.success(this._translate.instant('messages.deleted'))
      return true
    }))
  }
}
