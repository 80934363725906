<div class="tabs" fxHide fxLayout="row wrap-reverse" fxLayoutAlign="flex-start center" fxLayoutGap=".5rem" fxShow.gt-sm>
  <app-tab (click)="changeActiveTab(tab)" *ngFor="let tab of tabs" [label]="tab.label" [link]="tab.link"></app-tab>
</div>

<form [formGroup]="form" fxHide.gt-sm fxShow>
  <div class="form-group ngb-dropdown">
    <ng-select [clearable]="false" [searchable]="false" bindLabel="label" class="custom" formControlName="tab">
      <ng-option *ngFor="let tab of tabs" [value]="tab">{{tab.label | translate}}</ng-option>
    </ng-select>
  </div>
</form>


<div class="content">
  <router-outlet></router-outlet>
</div>
