import { JwtHelperService } from '@auth0/angular-jwt'
import { Subject } from 'rxjs'
import { TokenContentModel } from '../models'
import { Injectable } from "@angular/core";

export const TOKEN_KEY = 'token'

export function tokenGetter() {
  return localStorage.getItem(TOKEN_KEY)
}

@Injectable()
export class TokenService {

  public refreshToken: Subject<boolean> = new Subject()
  private jwtHelper = new JwtHelperService({
    tokenGetter: () => tokenGetter(),
  })
  private refreshTokenTimer = null

  public saveToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token)
  }

  public removeToken() {
    if (this.getToken()) {
      localStorage.removeItem(TOKEN_KEY)
    }
  }

  public getToken(): string {
    return tokenGetter()
  }

  public getTokenInfo(): TokenContentModel {
    if (!this.getToken() || this.isExpired()) {
      return null
    }
    return new TokenContentModel(this.jwtHelper.decodeToken(this.getToken()))
  }

  public isExpired(): boolean {
    return this.jwtHelper.isTokenExpired() as boolean;
  }

  public setTokenRefreshTimer() {
    let tokenExpired = true
    try {
      tokenExpired = this.isExpired()
    } catch (e) {
    }

    if (tokenExpired) {
      this.removeToken()
    } else {
      const tenMinutes = 10 * 60000
      const expiration = this.getExpirationDate().getTime()
      const timer = expiration - new Date().getTime() - tenMinutes
      if (this.refreshTokenTimer) {
        clearTimeout(this.refreshTokenTimer)
      }
      if (timer > 0) {
        this.refreshTokenTimer = setTimeout(() => this.refreshToken.next(), timer)
      } else {
        this.refreshToken.next()
      }
    }
  }

  public getExpirationDate(): Date {
    return this.jwtHelper.getTokenExpirationDate() as Date
  }
}
