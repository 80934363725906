export class TokenContentModel {
  id: string
  role: string
  aud: string
  exp: number
  username: string
  iss: string

  constructor(data) {
    this.id = data.Id
    this.role = data.UserRole
    this.aud = data.aud
    this.exp = data.exp
    this.username = data['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
    this.iss = data.iss
  }
}
