import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'

export class RefiModel {
  id: number
  financingLengthMin: number
  financingLengthMax: number
  rate: number
  validFrom: NgbDateStruct
  validTo: NgbDateStruct

  constructor(data) {
    if (data) {
      this.id = data.id
      this.financingLengthMin = data.financingLengthMin
      this.financingLengthMax = data.financingLengthMax
      this.rate = data.rate
      this.validFrom = data.validFrom
      this.validTo = data.validTo
    }
  }
}
