import { Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input('title') title: string
  @Input('confirmText') confirmText: string
  @Input('denyText') denyText: string

  constructor(public activeModal: NgbActiveModal) {
  }

}
