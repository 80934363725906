import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { isNullOrUndefined } from '../helpers/util.helper'
import { FilterModel } from './filter.model'

export class CatalogueModel {
  id: number
  carModel: CarModel
  name: string
  class: ClassModel
  gearbox: GearboxModel
  fuelType: FuelTypeModel
  engineCcm: number
  engineKw: number
  totalWeightKg: number
  priceExclVat: number
  priceInclVat: number

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.carModel = new CarModel(data.carModel)
      this.name = data.name
      this.class = new ClassModel(data.class)
      this.gearbox = new GearboxModel(data.gearbox)
      this.fuelType = new FuelTypeModel(data.fuelType)
      this.engineCcm = data.engineCcm
      this.engineKw = data.engineKw
      this.totalWeightKg = data.totalWeightKg
      this.priceExclVat = data.priceExclVat
      this.priceInclVat = data.priceInclVat
    }
  }
}

export class CarModel {
  id: number
  modelLine: ModelLineModel
  name: string
  saleFrom: NgbDateStruct
  saleTo: NgbDateStruct

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.modelLine = new ModelLineModel(data.modelLine)
      this.name = data.name
      this.saleFrom = data.saleFrom
      this.saleTo = data.saleTo
    }
  }
}

export class ModelLineModel {
  id: number
  name: string
  make: MakeModel
  saleFrom: NgbDateStruct
  saleTo: NgbDateStruct

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.make = new MakeModel(data.make)
      this.saleFrom = data.saleFrom
      this.saleTo = data.saleTo
    }
  }
}

export class MakeModel {
  id: number
  name: string

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
    }
  }
}

export class ClassModel {
  id: number
  name: string

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
    }
  }
}

export class GearboxModel {
  id: number
  name: string

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
    }
  }
}

export class FuelTypeModel {
  id: number
  name: string

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
    }
  }
}

export class CarListFilterModel extends FilterModel {
  make: MakeModel
  modelLine: ModelLineModel
  fuelType: FuelTypeModel
  model: CarModel
  gearbox: GearboxModel

  constructor(data?: CatalogueModel) {
    if (data) {
      super()
      this.orderBy = 'Catalogue'
      this.make = (data.carModel && data.carModel.modelLine) ? data.carModel.modelLine.make : null
      this.modelLine = data.carModel ? data.carModel.modelLine : null
      this.fuelType = data.fuelType
      this.model = data.carModel
      this.gearbox = data.gearbox
    } else {
      super()
      this.orderBy = 'Catalogue'
      this.make = null
      this.modelLine = null
      this.fuelType = null
      this.model = null
      this.gearbox = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.make || !!this.modelLine || !!this.fuelType || !!this.model || !!this.gearbox
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      makeId: this.make ? this.make.id.toString() : null,
      modelLineId: this.modelLine ? this.modelLine.id.toString() : null,
      fuelTypeId: this.fuelType ? this.fuelType.id.toString() : null,
      modelId: this.model ? this.model.id.toString() : null,
      gearboxId: this.gearbox ? this.gearbox.id.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}

export class MakeDropdownFilterModel extends FilterModel {
  userId: number

  constructor(data?) {
    super(data)
    this.pageSize = 200
    if (data) {
      this.userId = data.userId
    } else {
      this.userId = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.userId
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      userId: this.userId ? this.userId.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}

export class ModelLineFilterModel extends FilterModel {
  make: MakeModel

  constructor(data?) {
    super(data)
    this.pageSize = 200
    if (data) {
      this.make = data.make
    } else {
      this.make = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.make
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      makeId: this.make ? this.make.id.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}

export class CarModelFilterModel extends FilterModel {
  make: MakeModel
  modelLine: ModelLineModel

  constructor(data?) {
    super(data)
    this.pageSize = 200
    if (data) {
      this.make = data.make
      this.modelLine = data.modelLine
    } else {
      this.make = null
      this.modelLine = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.make || !!this.modelLine
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      makeId: this.make ? this.make.id.toString() : null,
      modelLineId: this.modelLine ? this.modelLine.id.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}

export enum CarType {
  Car = 0,
  Truck = 1
}
