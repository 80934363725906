import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'

export class PriceListBaseItemModel {
  id: number
  name: string
  validFrom: NgbDateStruct
  validTo: NgbDateStruct
  priceExclVAT: number

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.validFrom = data.validFrom
      this.validTo = data.validTo
      this.priceExclVAT = data.priceExclVAT
    }
  }
}
