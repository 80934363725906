import { isNullOrUndefined } from '../helpers/util.helper'
import { FilterModel } from './filter.model'
import { OrgUnitModel } from './org-unit.model'
import { PreferedLocalization } from './preferedLocalization.enum'

// user permission claims
export const CanManageUsersOrgUnits = 'CanManageUsersOrgUnits'
export const CanManageProductsServices = 'CanManageProductsServices'
export const CanManageInsurances = 'CanManageInsurances'
export const CanManageProvisions = 'CanManageProvisions'
export const CanManageCatalogue = 'CanManageCatalogue'
export const CanManageSettings = 'CanManageSettings'

// user edit permissions
export const CanEditCarsCatalogue = 'carsCatalogue'
export const CanEditCarsParameters = 'carsParameters'
export const CanEditproductConfigurationPneuservis = 'productConfigurationPneuservis'

export class UserModel {
  id: number
  name: string
  lastName: string
  username: string
  email: string
  phoneNumber: string
  photo: string
  role: UserRoleModel
  isAdmin: boolean
  organization: OrgUnitModel
  type: UserType
  status: UserStatus
  logoPath: string
  preferredLocalization: PreferedLocalization

  constructor(data) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.lastName = data.lastName
      this.username = data.username
      this.email = data.email
      this.phoneNumber = data.phoneNumber
      this.photo = data.photo
      this.role = data.role
      this.isAdmin = true //TODO handle role
      this.organization = new OrgUnitModel(data.organization)
      this.type = data.type
      this.status = data.status
      this.logoPath = data.logoPath ? 'url(' + data.logoPath + ')' : null
      this.preferredLocalization = data.preferredLocalization
    }
  }

  //TODO admin role

  get fullName(): string {
    return this.name + ' ' + this.lastName
  }
}

export enum UserType {
  External = 1,
  Internal = 2
}

export enum UserStatus {
  Invited = 1,
  Active = 2,
  Deactivated = 3,
  Uninvited = 4
}

export class UserRoleModel {
  id: number
  name: string
  claims: ClaimModel[]

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.claims = data.claims ? data.claims.map((c) => new ClaimModel(c)) : []
    }
  }
}

export class ClaimModel {
  id: number
  name: string

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
    }
  }
}

export interface SetPasswordModel {
  password: string;
  code: string;
}

export interface PasswordChangeModel {
  username: string;
  currentPassword: string;
  newPassword: string;
}

export class UserFilterModel extends FilterModel {
  role: UserRoleModel
  organization: OrgUnitModel
  type: UserType
  status: UserStatus
  hasRestrictions: boolean

  constructor(data?: UserFilterModel) {
    if (data) {
      super(data)
      this.role = data.role
      this.organization = new OrgUnitModel(data.organization)
      this.type = data.type
      this.status = data.status
      this.hasRestrictions = data.hasRestrictions
    } else {
      super()
      this.orderBy = 'Name' //TODO change to LastName
      this.role = null
      this.organization = null
      this.type = null
      this.status = null
      this.hasRestrictions = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.role || !!this.organization || !!this.type || !!this.status
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      RoleId: this.role ? this.role.id.toString() : null,
      OrganizationId: this.organization ? this.organization.id.toString() : null,
      UserType: this.type ? this.type.toString() : null,
      UserStatus: this.status ? this.status.toString() : null,
      hasRestrictions: this.hasRestrictions ? 'true' : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}

export class InvitationModel {
  name: string
  lastName: string
  email: string
  organizationId: number
  roleId: number

  constructor(data) {
    if (data) {
      this.name = data.name
      this.lastName = data.lastName
      this.email = data.email
      this.organizationId = data.organization.id
      this.roleId = data.role.id
    }
  }
}

export interface InvitationResult {
  invitation: {
    id: string;
    email: string;
    name: string;
    lastName: string;
    isActive: boolean;
    isUsed: boolean;
  };
  isInvited: boolean;
  message: string;
}

export class LoginResponse {
  token: string
  tenants: TenantModel[]

  constructor(data) {
    if (data) {
      this.token = data.token
      this.tenants = data.tenants ? data.tenants.map(x => new TenantModel(x)) : []
    }
  }
}

export class TenantModel {
  id: number
  name: string

  constructor(data) {
    if (data) {
      this.id = data.id
      this.name = data.name
    }
  }
}


//edit permission (sub menu level)
//naming convention moduleNameSectionName
export class EditPermissions {
  // cars module
  carsCatalogue: boolean
  carsParameters: boolean
  productConfigurationPneuservis: boolean


  constructor(data: EditPermissions) {
    if (data) {
      this.carsCatalogue = data.carsCatalogue
      this.carsParameters = data.carsParameters
      this.productConfigurationPneuservis = data.productConfigurationPneuservis
    }
  }
}
