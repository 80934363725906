import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ListModel, MakeRestrictionFilterModel, MakeRestrictionModel, MakeRestrictionType, OrgUnitModel, UserModel } from '../models'

@Injectable()
export class MakeRestrictionService {

  public url: string
  public filter: BehaviorSubject<MakeRestrictionFilterModel> = new BehaviorSubject<MakeRestrictionFilterModel>(new MakeRestrictionFilterModel())

  constructor(private _http: HttpClient) {
    this.url = '/api/administration/MakeRestriction'
  }

  public getList(): Observable<ListModel<MakeRestrictionModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<MakeRestrictionModel>) => {
          const list = new ListModel<MakeRestrictionModel>()
          list.total = res.total
          list.data = res.data.map((x) => new MakeRestrictionModel(x))
          return list
        }),
      )
  }

  public getUserList(searchText: string): Observable<UserModel[]> {
    return this._http.get(this.url + '/GetUsersWithoutRestrictions', { params: { searchText: searchText } })
      .pipe(
        map((res: UserModel[]) => res.map((x) => new UserModel(x))),
      )
  }

  public getOrgUnitList(searchText: string): Observable<OrgUnitModel[]> {
    return this._http.get(this.url + '/GetOrgUnitsWithoutRestrictions', { params: { searchText: searchText } })
      .pipe(
        map((res: OrgUnitModel[]) => res.map((x) => new OrgUnitModel(x))),
      )
  }

  public getRestriction(type: MakeRestrictionType, id: number): Observable<MakeRestrictionModel> {
    const getUrl = type === MakeRestrictionType.User ? '/UserRestrictions/' : '/OrgUnitRestrictions/'
    return this._http.get(this.url + getUrl + id)
      .pipe(
        map((res: MakeRestrictionModel) => new MakeRestrictionModel(res)),
      )
  }

  public save(restriction: MakeRestrictionModel): Observable<MakeRestrictionModel> {
    const saveUrl = restriction.type === MakeRestrictionType.User ? '/UserRestrictions/' : '/OrgUnitRestrictions'
    return this._http.post(this.url + saveUrl, restriction)
      .pipe(
        map((res: MakeRestrictionModel) => new MakeRestrictionModel(res)),
      )
  }

  public delete(restriction: MakeRestrictionModel): Observable<any> {
    const deleteUrl = restriction.type === MakeRestrictionType.User ? '/DeleteUserRestrictions/' : '/DeleteOrgUnitRestrictions/'
    return this._http.delete(this.url + deleteUrl + restriction.id)
  }
}
