import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

export const DIVIDER = '\u202f'

@Pipe({
  name: 'appMiles',
  pure: false,
})
export class AppMilesPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(value: number): string {
    if (value % 1000 === 0) {
      return Math.floor(value / 1000) + DIVIDER
        + this.translate.instant('common.thousand') + DIVIDER
        + this.translate.instant('common.miles')
    }
    return value + ' ' + this.translate.instant('common.miles')
  }

}
