import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'

import { map } from 'rxjs/operators'
import { FilterModel, ListModel } from '../../models'
import { PriceListBaseItemModel } from '../../models/price-list/price-list-base-item.models'
import { CommonDatepickerFunctions } from '../common-datepicker-functions'

@Injectable()
export class PriceListRoadTollService {

  public url: string
  public filter: BehaviorSubject<FilterModel> = new BehaviorSubject<FilterModel>(new FilterModel())

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/ServiceRoadToll'
  }

  public getList(): Observable<ListModel<PriceListBaseItemModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<PriceListBaseItemModel>) => {
          const list = new ListModel<PriceListBaseItemModel>()
          list.total = res.total
          res.data.forEach((x) => {
            x.validFrom = CommonDatepickerFunctions.toNgbDateStruct(x.validFrom)
            x.validTo = CommonDatepickerFunctions.toNgbDateStruct(x.validTo)
          })
          list.data = res.data.map((x) => new PriceListBaseItemModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<PriceListBaseItemModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: PriceListBaseItemModel) => {
          res.validFrom = CommonDatepickerFunctions.toNgbDateStruct(res.validFrom)
          res.validTo = CommonDatepickerFunctions.toNgbDateStruct(res.validTo)
          return new PriceListBaseItemModel(res)
        }),
      )
  }

  public save(roadToll: PriceListBaseItemModel): Observable<boolean> {
    const entity: any = roadToll
    entity.validFrom = CommonDatepickerFunctions.getDataDate(roadToll.validFrom)
    entity.validTo = CommonDatepickerFunctions.getDataDate(roadToll.validTo)

    return this._http.post(this.url, entity)
      .pipe(map(() => {
        this._toaster.success(this._translate.instant('messages.created'))
        return true
      }))
  }

  public update(roadToll: PriceListBaseItemModel, roadTollId: Number): Observable<boolean> {
    const entity: any = roadToll
    entity.validFrom = CommonDatepickerFunctions.getDataDate(roadToll.validFrom)
    entity.validTo = CommonDatepickerFunctions.getDataDate(roadToll.validTo)

    return this._http.put(this.url + '/' + roadTollId, entity)
      .pipe(map(() => {
        this._toaster.success(this._translate.instant('messages.updated'))
        return true
      }))
  }

  public delete(roadTollId: number): Observable<boolean> {
    return this._http.delete(this.url + '/' + roadTollId).pipe(
      map(() => {
        this._toaster.success(this._translate.instant('messages.deleted'))
        return true
      }),
    )
  }

}
