import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { FilterModel, InsuranceCompanyModel, ListModel } from '../../models'

@Injectable()
export class InsuranceCompanyService {

  public url: string
  public filter: BehaviorSubject<FilterModel>

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/InsuranceCompany'
    this.filter = new BehaviorSubject<FilterModel>(new FilterModel())
  }

  public getList(): Observable<ListModel<InsuranceCompanyModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<InsuranceCompanyModel>) => {
          const list = new ListModel<InsuranceCompanyModel>()
          list.total = res.total
          list.data = res.data.map((x) => new InsuranceCompanyModel(x))
          return list
        }),
      )
  }

  public getDropdownList(filter: FilterModel): Observable<ListModel<InsuranceCompanyModel>> {
    return this._http.get(this.url, { params: filter.toBeFilter() })
      .pipe(
        map((res: ListModel<InsuranceCompanyModel>) => {
          const list = new ListModel<InsuranceCompanyModel>()
          list.total = res.total
          list.data = res.data.map((x) => new InsuranceCompanyModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<InsuranceCompanyModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: InsuranceCompanyModel) => new InsuranceCompanyModel(res)),
      )
  }

  public save(item: InsuranceCompanyModel): Observable<InsuranceCompanyModel> {
    return this._http.post(this.url, item)
      .pipe(
        map((res: InsuranceCompanyModel) => {
          this._toaster.success(this._translate.instant('messages.created'))
          return new InsuranceCompanyModel(res)
        }))
  }

  public update(item: InsuranceCompanyModel): Observable<InsuranceCompanyModel> {
    return this._http.put(this.url + '/' + item.id, item)
      .pipe(
        map((res: InsuranceCompanyModel) => {
          this._toaster.success(this._translate.instant('messages.updated'))
          return new InsuranceCompanyModel(res)
        }))
  }

  public delete(id: number): Observable<boolean> {
    return this._http.delete(this.url + '/' + id).pipe(
      map(() => {
        this._toaster.success(this._translate.instant('messages.deleted'))
        return true
      }))
  }
}
