import { isNullOrUndefined } from '../helpers/util.helper'
import { MakeModel } from './car.models'
import { FilterModel } from './filter.model'
import { OrgUnitModel } from './org-unit.model'
import { UserModel } from './user.model'

export enum MakeRestrictionType {
  User = 1,
  OrgUnit = 2
}

export class MakeRestrictionModel {
  user: UserModel
  orgUnit: OrgUnitModel
  makes: Array<MakeModel>
  type: MakeRestrictionType

  constructor(data) {
    if (data) {
      this.user = data.user ? new UserModel(data.user) : null
      this.orgUnit = data.orgUnit ? new OrgUnitModel(data.orgUnit) : null
      this.makes = data.makes ? data.makes.map((x) => new MakeModel(x)) : []
      this.type = data.user ? MakeRestrictionType.User : MakeRestrictionType.OrgUnit
    }
  }

  get name(): string {
    return this.type === MakeRestrictionType.User ? this.user.fullName : this.orgUnit.name
  }

  get id(): number {
    return this.type === MakeRestrictionType.User ? this.user.id : this.orgUnit.id
  }
}

export class MakeRestrictionFilterModel extends FilterModel {
  user: UserModel
  make: MakeModel
  orgUnit: OrgUnitModel

  constructor(data?: MakeRestrictionFilterModel) {
    if (data) {
      super(data)
      this.user = data.user ? new UserModel(data.user) : null
      this.orgUnit = data.orgUnit ? new OrgUnitModel(data.orgUnit) : null
      this.make = data.make ? new MakeModel(data.make) : null
    } else {
      super()
      this.orderBy = 'User'
      this.user = null
      this.orgUnit = null
      this.make = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.user || !!this.orgUnit
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      UserId: this.user ? this.user.id.toString() : null,
      OrgUnitId: this.orgUnit ? this.orgUnit.id.toString() : null,
      MakeId: this.make ? this.make.id.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}
