import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ListModel, OrgUnitFilter, OrgUnitModel, OrgUnitStats, OrgUnitType } from '../models'

@Injectable()
export class OrgUnitService {

  public url: string
  public filter: BehaviorSubject<OrgUnitFilter> = new BehaviorSubject<OrgUnitFilter>(new OrgUnitFilter())

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/OrgUnit'
  }

  public getList(): Observable<ListModel<OrgUnitModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<OrgUnitModel>) => {
          const list = new ListModel<OrgUnitModel>()
          list.total = res.total
          list.data = res.data.map((x) => new OrgUnitModel(x))
          return list
        }),
      )
  }

  public getDropdownList(filter: OrgUnitFilter): Observable<ListModel<OrgUnitModel>> {
    return this._http.get(this.url, { params: filter.toBeFilter() })
      .pipe(
        map((res: ListModel<OrgUnitModel>) => {
          const list = new ListModel<OrgUnitModel>()
          list.total = res.total
          list.data = res.data.map((x) => new OrgUnitModel(x))
          return list
        }),
      )
  }

  public getAll(): Observable<OrgUnitModel[]> {
    return this._http.get(this.url + '/Retrieve')
      .pipe(
        map((res: OrgUnitModel[]) => res.map((x) => new OrgUnitModel(x))),
      )
  }

  public getStats(): Observable<OrgUnitStats> {
    return this._http.get<OrgUnitStats>(this.url + '/GetOrgUnitStatistics')
  }

  public getDetail(id: number): Observable<OrgUnitModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: OrgUnitModel) => new OrgUnitModel(res)),
      )
  }

  public save(organization: OrgUnitModel): Observable<OrgUnitModel> {
    if (Number(organization.type) === OrgUnitType.Central) {
      delete organization.superOrgUnit
      delete organization.superOrgUnitId
    }
    return this._http.post(this.url, organization)
      .pipe(
        map((res: OrgUnitModel) => {
          this._toaster.success(this._translate.instant('messages.created'))
          return new OrgUnitModel(res)
        }),
      )
  }

  public update(organization: OrgUnitModel): Observable<OrgUnitModel> {
    if (Number(organization.type) === OrgUnitType.Central) {
      delete organization.superOrgUnit
      delete organization.superOrgUnitId
    }
    return this._http.put(this.url + '/' + organization.id, organization)
      .pipe(
        map((res: OrgUnitModel) => {
          this._toaster.success(this._translate.instant('messages.updated'))
          return new OrgUnitModel(res)
        }),
      )
  }

  public delete(organization: OrgUnitModel): Observable<boolean> {
    return this._http.delete(this.url + '/' + organization.id).pipe(
      map(() => {
        this._toaster.success(this._translate.instant('messages.deleted'))
        return true
      }),
    )
  }
}
