<div (click)="onClick.emit(key)" [class.active]="filter?.orderBy === key"
     [class.clickable]="key"
     [class.descending]="filter?.orderDesc"
     fxLayout
     fxLayoutAlign="flex-{{align}} center"
     fxLayoutGap=".5rem">
  <span [translate]="label" class="body-text" fxFlex="0 0 auto"></span>
  <app-icon *ngIf="key" [icon]="filter.orderDesc && filter.orderBy === key ? 'chevron-up' : 'chevron-down'"
            fxFlex="0 0 auto"
            size="xs"></app-icon>
</div>
