import {Directive, OnDestroy} from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import {
  CanEditCarsCatalogue,
  CanEditCarsParameters,
  CanEditproductConfigurationPneuservis,
  CanManageCatalogue,
  CanManageInsurances,
  CanManageProductsServices,
  CanManageProvisions,
  CanManageSettings,
  CanManageUsersOrgUnits,
} from '../../../models'

@Directive({
  selector: 'app-base',
})
export class BaseComponent implements OnDestroy {

  public alive = true
  public alive$ = new Subject<boolean>()
  public isLoaded$ = new BehaviorSubject<Boolean>(true)

  // constants (fot possibility use in template)
  readonly CanManageUsersOrgUnits = CanManageUsersOrgUnits
  readonly CanManageProductsServices = CanManageProductsServices
  readonly CanManageInsurances = CanManageInsurances
  readonly CanManageProvisions = CanManageProvisions
  readonly CanManageCatalogue = CanManageCatalogue
  readonly CanManageSettings = CanManageSettings

  readonly CanEditCarsCatalogue = CanEditCarsCatalogue
  readonly CanEditCarsParameters = CanEditCarsParameters
  readonly CanEditproductConfigurationPneuservis = CanEditproductConfigurationPneuservis

  ngOnDestroy(): void {
    this.alive = false
    this.alive$.next(false)
  }

}
