import { Component, OnInit } from '@angular/core'
import { NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { filter, takeWhile } from 'rxjs/internal/operators'
import { BaseComponent } from '../base/base.component'

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends BaseComponent implements OnInit {

  public isPublic: boolean
  public routeConfigLoaded$ = new BehaviorSubject<Boolean>(true)

  constructor(private _router: Router) {
    super()
  }

  ngOnInit() {
    this._router.events.pipe(
      takeWhile(() => this.alive),
      filter(e => e instanceof NavigationEnd),
    )
      .subscribe((event: NavigationStart) => {
        this.isPublic = event.url && event.url.startsWith('/public')
      })

    this._router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.routeConfigLoaded$.next(false)
      } else if (event instanceof RouteConfigLoadEnd) {
        this.routeConfigLoaded$.next(true)
      }
    })
  }

}
