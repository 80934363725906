import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { isNullOrUndefined } from '../helpers/util.helper'
import { CarType, MakeModel } from './car.models'
import { FilterModel } from './filter.model'
import { ProductModel } from './product.model'

export class InsuranceCompanyModel {
  id: number
  name: string

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
    }
  }
}

export class InsuranceProductModel {
  id: number
  name: string
  description: string
  products: ProductModel[]
  type: InsuranceProductType
  subType: InsuranceProductSubType
  commitment: InsuranceProductCommitment
  baseType: InsuranceProductBaseType
  carMakeRequired: boolean
  isPresetProductForCompany: boolean

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.description = data.description
      this.products = data.products ? data.products.map(p => new ProductModel(p)) : []
      this.type = data.type
      this.subType = data.subType
      this.commitment = data.commitment
      this.baseType = data.baseType
      this.carMakeRequired = data.carMakeRequired
      this.isPresetProductForCompany = data.isPresetProductForCompany
    }
  }
}

export enum InsuranceProductType {
  Basic = 0,
  Additional = 1
}

export enum InsuranceProductSubType {
  Null = 0,
  Participation = 1,
  Coverage = 2,
  Multiplicity = 3
}

export enum InsuranceProductBaseType {
  Percent = 0,
  EngineCcm = 1,
  Amount = 2,
  Combined = 3
}

export enum InsuranceProductCommitment {
  RequiredVisible = 0,
  RequiredInvisible = 1,
  Available = 2,
  Unavailable = 3
}

export class InsuranceProductFilterModel extends FilterModel {
  product: ProductModel
  type: InsuranceProductType
  subType: InsuranceProductSubType
  baseType: InsuranceProductBaseType
  commitment: InsuranceProductCommitment

  constructor(data?: InsuranceProductFilterModel) {
    if (data) {
      super(data)
      this.product = new ProductModel(data.product)
      this.type = data.type
      this.subType = data.subType
      this.baseType = data.baseType
      this.commitment = data.commitment
    } else {
      super()
      this.orderBy = 'Name'
      this.product = null
      this.type = null
      this.subType = null
      this.baseType = null
      this.commitment = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText ||
      !!this.product ||
      !!this.type ||
      !!this.subType ||
      !!this.baseType ||
      !!this.commitment
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      ProductId: this.product ? this.product.id.toString() : null,
      type: this.type ? this.type.toString() : null,
      subType: this.subType ? this.subType.toString() : null,
      baseType: this.baseType ? this.baseType.toString() : null,
      commitment: this.commitment ? this.commitment.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}

export class InsuranceRateModel {
  id: number
  name: string
  displayName: string

  carTypes: CarType[]
  insuranceProduct: InsuranceProductModel
  insuranceCompany: InsuranceCompanyModel
  carMake: MakeModel

  engineCcmFrom: number
  engineCcmTo: number

  calculationMethod: CalculationMethod
  rate: number

  seatsMin?: number
  seatsMax?: number

  productRestrictions: InsuranceProductModel[]
  description?: string
  insuranceConditions?: string
  userInformation?: string

  validFrom: NgbDateStruct
  validTo: NgbDateStruct

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.displayName = data.displayName

      this.carTypes = data.carTypes ? data.carTypes : []
      this.insuranceProduct = data.insuranceProduct ? new InsuranceProductModel(data.insuranceProduct) : null
      this.insuranceCompany = data.insuranceCompany ? new InsuranceCompanyModel(data.insuranceCompany) : null
      this.carMake = data.carMake ? new MakeModel(data.carMake) : null

      this.engineCcmFrom = data.engineCcmFrom
      this.engineCcmTo = data.engineCcmTo

      this.calculationMethod = data.calculationMethod
      this.rate = data.rate

      this.seatsMin = data.seatsMin
      this.seatsMax = data.seatsMax

      this.productRestrictions = data.productRestrictions ? data.productRestrictions.map(p => new InsuranceProductModel(p)) : []
      this.description = data.description
      this.insuranceConditions = data.insuranceConditions
      this.userInformation = data.userInformation

      this.validFrom = data.validFrom
      this.validTo = data.validTo
    }
  }
}

export enum CalculationMethod {
  Percent = 0,
  Amount = 1
}

export class InsuranceRateFilterModel extends FilterModel {
  insuranceProduct: InsuranceProductModel
  insuranceCompany: InsuranceCompanyModel
  carMake: MakeModel

  constructor(data?: InsuranceRateFilterModel) {
    if (data) {
      super(data)
      this.insuranceProduct = new InsuranceProductModel(data.insuranceProduct)
      this.insuranceCompany = new InsuranceCompanyModel(data.insuranceCompany)
      this.carMake = new MakeModel(data.carMake)
    } else {
      super()
      this.orderBy = 'DisplayName'
      this.insuranceProduct = null
      this.insuranceCompany = null
      this.carMake = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText ||
      !!this.insuranceProduct ||
      !!this.insuranceCompany ||
      !!this.carMake
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      InsuranceProductId: this.insuranceProduct ? this.insuranceProduct.id.toString() : null,
      InsuranceCompanyId: this.insuranceCompany ? this.insuranceCompany.id.toString() : null,
      CarMakeId: this.carMake ? this.carMake.id.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}

export class InsuranceRestrictionModel {
  id: number
  company: InsuranceCompanyModel
  description: string
  product: InsuranceProductModel
  valueFrom: number

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.company = data.company ? new InsuranceCompanyModel(data.company) : null
      this.description = data.description
      this.product = data.product ? new InsuranceProductModel(data.product) : null
      this.valueFrom = data.valueFrom
    }
  }
}

export class InsuranceRestrictionFilterModel extends FilterModel {
  product: InsuranceProductModel
  company: InsuranceCompanyModel

  constructor(data?: InsuranceRateFilterModel) {
    if (data) {
      super(data)
      this.product = new InsuranceProductModel(data.insuranceProduct)
      this.company = new InsuranceCompanyModel(data.insuranceCompany)
    } else {
      super()
      this.orderBy = 'Product'
      this.product = null
      this.company = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.product || !!this.company
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      InsuranceProductId: this.product ? this.product.id.toString() : null,
      InsuranceCompanyId: this.company ? this.company.id.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}
