import { Injectable } from '@angular/core'
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { isNumber } from '../../../helpers/util.helper'
import {TranslationWidth} from "@angular/common";

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _translate: TranslateService) {
    super()
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.getWeekdayShortName(weekday)
  }

  getWeekdayShortName(weekday: number): string {
    return this._translate.instant('common.weekday.' + weekday).substring(0, 2)
  }

  getMonthShortName(month: number): string {
    return this.getMonthFullName(month)
  }

  getMonthFullName(month: number): string {
    return this._translate.instant('common.month.' + month)
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${ date.day }-${ date.month }-${ date.year }`
  }

}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      let result: NgbDateStruct
      const dateParts = value.trim().split('.')
      if (areNumbers(dateParts) && dateParts.length <= 3) {
        if (dateParts.length === 1) {
          result = { day: null, month: null, year: toInteger(dateParts[0]) }
        } else if (dateParts.length === 2) {
          result = { day: null, month: toInteger(dateParts[0]), year: toInteger(dateParts[1]) }
        } else {
          result = {
            day: toInteger(dateParts[0]),
            month: toInteger(dateParts[1]),
            year: toInteger(dateParts[2]),
          }
        }

        if (result.year >= 1900) {
          return result
        }
      }
    }
    return null
  }

  format(date: NgbDateStruct): string {
    let stringDate = ''
    if (date) {
      stringDate += isNumber(date.day) ? padNumber(date.day) + '.' : ''
      stringDate += isNumber(date.month) ? padNumber(date.month) + '.' : ''
      stringDate += date.year
    }
    return stringDate
  }
}

function toInteger(value: string): number {
  return parseInt(value, 10)
}

function areNumbers(value: string[]): boolean {
  value.forEach(n => {
    if (!isNumber(n)) {
      return false
    }
  })
  return true
}

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${ value }`.slice(-2)
  } else {
    return ''
  }
}
