<a *ngIf="!submenu" [routerLink]="link" class="navbar-item" fxLayout fxLayoutAlign="flex-start center"
   routerLinkActive="active">
  <app-icon [icon]="icon" fxFlex="0 0 auto" size="sm"></app-icon>
  <span [translate]="label" class="label" fxFlex="1 1 auto"></span>
</a>

<div *ngIf="submenu" [class.active]="isRouteActive" class="navbar-menu">
  <div (click)="showSubmenu = !showSubmenu" [class.active]="isRouteActive" class="navbar-item" fxLayout
       fxLayoutAlign="flex-start center">
    <app-icon [icon]="icon" fxFlex="0 0 auto" size="sm"></app-icon>
    <span [translate]="label" class="label" fxFlex="1 1 auto"></span>
  </div>

  <div [class.hidden]="!showSubmenu" class="subitems">
    <ng-content select="[subitems]"></ng-content>
  </div>
</div>
