import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core'
import { ControlValueAccessor, DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { isNullOrUndefined } from '../helpers/util.helper'

export const SPLITTER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FloatInputFormatterDirective),
  multi: true,
}

@Directive({
  selector: '[appFloatFormatter]',
  providers: [SPLITTER_VALUE_ACCESSOR],
})
export class FloatInputFormatterDirective extends DefaultValueAccessor implements ControlValueAccessor {

  public onChange
  public onTouched
  public previousValue

  constructor(private renderer: Renderer2,
              private element: ElementRef) {
    super(renderer, element, true)
  }

  @HostListener('input', ['$event.target.value'])
  input(value) {
    let spacesCount = value.trim().split(' ').length - 1
    const deleted = this.previousValue.length > value.length
    const deletedSpace = deleted && this.previousValue.split(' ').length > value.split(' ').length
    let numbers = value.replace(/[^0-9,.]/g, '')

    const commaUsed = numbers.indexOf(',') !== -1
    numbers = commaUsed ? numbers.replace(',', '.') : numbers

    if (deletedSpace) {
      const cursorIndex = this.element.nativeElement.selectionStart
      value = value.slice(0, cursorIndex - 1) + value.slice(cursorIndex, value.length)
      numbers = value.replace(/[^0-9,.]/g, '').replace(',', '.')
      spacesCount++
    }

    const startsWithZero = this.previousValue === '0' && !!numbers.match(/^0[1-9]+/)
    numbers = startsWithZero ? numbers.substr(1) : numbers

    const endWithZero = this.previousValue === '0' && !!numbers.match(/^[1-9]+0$/)
    numbers = endWithZero ? numbers.substr(0, 1) : numbers

    if (numbers.indexOf('.') !== -1) {
      const integer = numbers.split('.')[0]
      const decimal = numbers.split('.')[1] ? numbers.split('.')[1].substr(0, 2) : ''
      numbers = integer + '.' + decimal
    }

    const rounded = roundToTwo(parseFloat(numbers) || 0)
    this.onChange(rounded)

    value = numbers.match(/^[0-9]+\.?[0-9]{0,2}$/) ? numbers : rounded
    this.writeValue(value, deleted, spacesCount, commaUsed)
  }

  @HostListener('focus')
  focus() {
    this.onTouched()
  }

  writeValue(value: any, deleted?: boolean, oldSpacesCount?: number, commaUsed?: boolean): void {
    const element = this.element.nativeElement
    let cursorPosition = element.selectionStart
    const rounded = roundToTwo(parseFloat(value) || 0)
    value = value && value.toString().match(/^[0-9]+\.?[0-9]{0,2}$/) ? value : rounded
    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') // add spaces
    value = commaUsed ? value.replace('.', ',') : value

    this.renderer.setProperty(element, 'value', value)

    if (!isNullOrUndefined(oldSpacesCount)) {
      const newSpacesCount = value.split(' ').length - 1
      if (newSpacesCount > oldSpacesCount) {
        cursorPosition++
      }
      if (newSpacesCount < oldSpacesCount) {
        cursorPosition--
      }
      this.renderer.setProperty(element, 'selectionStart', cursorPosition)
      this.renderer.setProperty(element, 'selectionEnd', cursorPosition)
    }

    this.previousValue = value
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  setDisabledState(isDisabled: boolean): void {
    const element = this.element.nativeElement
    if (isDisabled) {
      this.renderer.setAttribute(element, 'disabled', isDisabled.toString())
    } else {
      this.renderer.removeAttribute(element, 'disabled')
    }
  }
}

export function roundToTwo(num): number {
  num = num + 'e+2'
  return +(Math.round(num) + 'e-2')
}
