import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ListModel } from '../../models'
import { PriceListRegistrationFeeModel, RegistrationFeeFilter } from '../../models/price-list/price-list-registration-fee.model'

@Injectable()
export class PriceListRegistrationFeeService {

  public url: string
  public filter: BehaviorSubject<RegistrationFeeFilter> = new BehaviorSubject<RegistrationFeeFilter>(new RegistrationFeeFilter())

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/RegistrationFee'
  }

  public getList(): Observable<ListModel<PriceListRegistrationFeeModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<PriceListRegistrationFeeModel>) => {
          const list = new ListModel<PriceListRegistrationFeeModel>()
          list.total = res.total
          list.data = res.data.map((x) => new PriceListRegistrationFeeModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<PriceListRegistrationFeeModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: PriceListRegistrationFeeModel) => {
          return new PriceListRegistrationFeeModel(res)
        }),
      )
  }

  public save(item: PriceListRegistrationFeeModel): Observable<boolean> {
    return this._http.post(this.url, item)
      .pipe(map(() => {
        this._toaster.success(this._translate.instant('messages.created'))
        return true
      }))
  }

  public update(item: PriceListRegistrationFeeModel): Observable<boolean> {
    return this._http.put(this.url + '/' + item.id, item)
      .pipe(map(() => {
        this._toaster.success(this._translate.instant('messages.updated'))
        return true
      }))
  }

  public delete(itemId: number): Observable<boolean> {
    return this._http.delete(this.url + '/' + itemId).pipe(
      map(() => {
        this._toaster.success(this._translate.instant('messages.deleted'))
        return true
      }),
    )
  }

}
