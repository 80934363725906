import { PriceListBaseItemModel } from './price-list-base-item.models'

export class PriceListFeeModel extends PriceListBaseItemModel {
  description: string
  periodicity: FeePeriodicityType

  constructor(data) {
    if (data) {
      super(data)
      this.description = data.description
      this.periodicity = data.periodicity
    }
  }
}

export enum FeePeriodicityType {
  Onetime = 0,
  Month = 1,
  Year = 2
}
