import { isNullOrUndefined } from '../helpers/util.helper'
import { FilterModel } from './filter.model'

export class OrgUnitModel {
  id: number
  name: string
  ico: string
  street: string
  number: string
  city: string
  country: string
  zip: string
  logoPath: string
  type: OrgUnitType
  superOrgUnit: OrgUnitModel
  superOrgUnitId: number

  constructor(data) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.ico = data.ico
      this.street = data.street
      this.number = data.number
      this.city = data.city
      this.country = data.country
      this.zip = data.zip
      this.logoPath = data.logoPath
      this.type = data.type
      this.superOrgUnit = data.superOrgUnit ? data.superOrgUnit : {
        id: data.superOrgUnitId,
        name: data.superOrgUnitName,
      }
      this.superOrgUnitId = this.superOrgUnit.id
    }
  }
}

export enum OrgUnitType {
  Central = 0,
  Level1 = 1,
  Level2 = 2,
  Level3 = 3
}

export interface OrgUnitStats {
  level1Count: number;
  level2Count: number;
  level3Count: number;
}

export class OrgUnitFilter extends FilterModel {
  type: OrgUnitType
  provisionsOnly: boolean
  hasRestrictions: boolean

  constructor(data?: OrgUnitFilter) {
    if (data) {
      super(data)
      this.type = data.type
      this.provisionsOnly = data.provisionsOnly
      this.hasRestrictions = data.hasRestrictions
    } else {
      super()
      this.orderBy = 'Name'
      this.type = null
      this.hasRestrictions = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.type
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      types: !isNullOrUndefined(this.type) ? [this.type].toString() : null,
      provisionsOnly: this.provisionsOnly ? 'true' : null,
      hasRestrictions: this.hasRestrictions ? 'true' : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}
