import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { ListModel, ServiceTypeFilter, ServiceTypeModel } from '../models'
import { CommonDatepickerFunctions } from './common-datepicker-functions'

@Injectable()
export class ServiceTypeService {

  public url: string
  public filter: BehaviorSubject<ServiceTypeFilter> = new BehaviorSubject<ServiceTypeFilter>(new ServiceTypeFilter())

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/ServiceType'
  }

  public getList(): Observable<ListModel<ServiceTypeModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<ServiceTypeModel>) => {
          const list = new ListModel<ServiceTypeModel>()
          list.total = res.total
          res.data.forEach((x) => {
            if (x.serviceFee) {
              x.serviceFee.validFrom = CommonDatepickerFunctions.toNgbDateStruct(x.serviceFee.validFrom)
              x.serviceFee.validTo = CommonDatepickerFunctions.toNgbDateStruct(x.serviceFee.validTo)
            }
          })
          list.data = res.data.map((x) => new ServiceTypeModel(x))
          return list
        }),
      )
  }

  public getDropdownList(filter: ServiceTypeFilter): Observable<ListModel<ServiceTypeModel>> {
    return this._http.get(this.url, { params: filter.toBeFilter() })
      .pipe(
        map((res: ListModel<ServiceTypeModel>) => {
          const list = new ListModel<ServiceTypeModel>()
          list.total = res.total
          list.data = res.data.map((x) => new ServiceTypeModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<ServiceTypeModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: ServiceTypeModel) => {
          if (res.serviceFee) {
            res.serviceFee.validFrom = CommonDatepickerFunctions.toNgbDateStruct(res.serviceFee.validFrom)
            res.serviceFee.validTo = CommonDatepickerFunctions.toNgbDateStruct(res.serviceFee.validTo)
          }
          return new ServiceTypeModel(res)
        }),
      )
  }

  public save(serviceType: ServiceTypeModel): Observable<ServiceTypeModel> {
    const entity: any = serviceType
    if (entity.serviceFee) {
      entity.serviceFee.validFrom = CommonDatepickerFunctions.getDataDate(serviceType.serviceFee.validFrom)
      entity.serviceFee.validTo = CommonDatepickerFunctions.getDataDate(serviceType.serviceFee.validTo)
    }

    return this._http.post(this.url, entity)
      .pipe(map((res: ServiceTypeModel) => {
        this._toaster.success(this._translate.instant('messages.created'))
        return new ServiceTypeModel(res)
      }))
  }

  public update(serviceType: ServiceTypeModel, serviceTypeId: Number): Observable<ServiceTypeModel> {
    const entity: any = serviceType
    if (entity.serviceFee) {
      entity.serviceFee.validFrom = CommonDatepickerFunctions.getDataDate(serviceType.serviceFee.validFrom)
      entity.serviceFee.validTo = CommonDatepickerFunctions.getDataDate(serviceType.serviceFee.validTo)
    }

    return this._http.put(this.url + '/' + serviceTypeId, entity)
      .pipe(map((res: ServiceTypeModel) => {
        this._toaster.success(this._translate.instant('messages.updated'))
        return new ServiceTypeModel(res)
      }))
  }

  public delete(serviceTypeId: number): Observable<boolean> {
    return this._http.delete(this.url + '/' + serviceTypeId).pipe(
      map(() => {
        this._toaster.success(this._translate.instant('messages.deleted'))
        return true
      }),
    )
  }

}
