import { isNullOrUndefined } from '../helpers/util.helper'
import { FilterModel } from './filter.model'
import { InsuranceProductCommitment } from './insurance.models'
import { PriceListAdministrationFeeModel } from './price-list/price-list-administration-fee.model'
import { PriceListFeeModel } from './price-list/price-list-fee.model'
import { ProductModel } from './product.model'

export class ServiceTypeModel {
  id: number
  name: string
  description: string
  serviceKind: ServiceKindType
  commitment: InsuranceProductCommitment
  products: ProductModel[]
  serviceFee: PriceListFeeModel
  calculationType: CalculationType
  serviceAdministrationFee: PriceListAdministrationFeeModel

  constructor(data) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.description = data.description
      this.serviceKind = data.serviceKind
      this.commitment = data.commitment
      this.products = data.products ? data.products.map(x => new ProductModel(x)) : []
      this.calculationType = data.calculationType
      if (data.serviceFee) {
        this.serviceFee = new PriceListFeeModel(data.serviceFee)
      }
      if (data.serviceAdministrationFee) {
        this.serviceAdministrationFee = new PriceListAdministrationFeeModel(data.serviceAdministrationFee)
      }
    }
  }
}

export enum ServiceKindType {
  Maintenance = 1,
  ReplacementCar = 2,
  RoadToll = 3,
  FuelCard = 4,
  Fee = 5,
  RegisterFee = 6,
  RoadTax = 7,
  PneuService = 10,
  AdministrationFee = 11
}

export enum CalculationType {
  Repayment = 0,
  CalculationInput = 1
}

export class ServiceTypeFilter extends FilterModel {
  serviceKind: ServiceKindType
  commitment: InsuranceProductCommitment
  product: ProductModel

  constructor(data?: ServiceTypeFilter) {
    if (data) {
      super(data)
      this.serviceKind = data.serviceKind
      this.commitment = data.commitment
      this.product = new ProductModel(data.product)
    } else {
      super()
      this.orderBy = 'Name'
      this.serviceKind = null
      this.commitment = null
      this.product = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.serviceKind || !!this.commitment || !!this.product
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      serviceKind: this.serviceKind ? this.serviceKind.toString() : null,
      commitment: this.commitment ? this.commitment.toString() : null,
      ProductId: this.product ? this.product.id.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}
