export class GeneralSettingsModel {
  id: number

  tenantName: string
  offerValidityDays: number
  daysBeforeArchive: number
  decimalPrecision: DecimalPrecision

  prefix: string
  paddedNumber: string

  insuranceVATRatePerc: number
  financngVATRatePerc: number
  serviceVATRatePerc: number
  dphRate: number

  insuranceAmount: InsuranceAmount

  winterPneuLifetimeKm: number
  summerPneuLifetimeKm: number
  summerPneuMonthCount: number
  winterSeasonStartDay: number
  winterSeasonStartMonth: number
  winterSeasonEndDay: number
  winterSeasonEndMonth: number

  financingLengthMin: number
  financingLengthMax: number
  contractualMileageMin: number
  contractualMileageMax: number

  preferredLocalization: string
  currency: string

  smtpHost: string
  smtpPassword: string
  smtpPort: string
  smtpSourceMail: string
  smtpUserName: string
  smtpEnableSSL: boolean

  gdprDays: number
  gdprText: string

  constructor(data?) {
    if (data) {
      this.id = data.id

      this.tenantName = data.tenantName
      this.offerValidityDays = data.offerValidityDays
      this.daysBeforeArchive = data.daysBeforeArchive
      this.decimalPrecision = data.decimalPrecision

      this.prefix = data.prefix
      this.paddedNumber = data.paddedNumber

      this.insuranceVATRatePerc = data.insuranceVATRatePerc
      this.financngVATRatePerc = data.financngVATRatePerc
      this.serviceVATRatePerc = data.serviceVATRatePerc
      this.dphRate = data.dphRate

      this.insuranceAmount = data.insuranceAmount

      this.winterPneuLifetimeKm = data.winterPneuLifetimeKm
      this.summerPneuLifetimeKm = data.summerPneuLifetimeKm
      this.summerPneuMonthCount = data.summerPneuMonthCount
      this.winterSeasonStartDay = data.winterSeasonStartDay
      this.winterSeasonStartMonth = data.winterSeasonStartMonth
      this.winterSeasonEndDay = data.winterSeasonEndDay
      this.winterSeasonEndMonth = data.winterSeasonEndMonth

      this.financingLengthMin = data.financingLengthMin
      this.financingLengthMax = data.financingLengthMax
      this.contractualMileageMin = data.contractualMileageMin
      this.contractualMileageMax = data.contractualMileageMax

      this.preferredLocalization = data.preferredLocalization
      this.currency = data.currency

      this.smtpHost = data.smtpHost
      this.smtpPort = data.smtpPort
      this.smtpUserName = data.smtpUserName
      this.smtpPassword = data.smtpPassword
      this.smtpSourceMail = data.smtpSourceMail
      this.smtpEnableSSL = data.smtpEnableSSL

      this.gdprDays = data.gdprDays
      this.gdprText = data.gdprText
    }
  }
}

export enum InsuranceAmount {
  PriceExclVAT = 0,
  PriceInclVAT = 1
}

export enum DecimalPrecision {
  Zero = 0,
  One = 1,
  Two = 2
}
