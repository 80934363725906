import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'

export class CommonDatepickerFunctions {

  public static toNgbDateStruct(date: any): NgbDateStruct {
    const asDate: Date = date ? new Date(date.substring(0, 10)) : null
    if (asDate) {
      return {
        day: asDate.getUTCDate(),
        month: asDate.getUTCMonth() + 1,
        year: asDate.getUTCFullYear(),
      }
    }
    return null
  }

  public static getDataDate(date: NgbDateStruct): string {
    if (!date) {
      return null
    }
    return date.year + '-'
      + (date.month < 10 ? ('0' + date.month) : date.month) + '-'
      + (date.day < 10 ? ('0' + date.day) : date.day)
    // + 'T00:00:00.000Z';
  }

}
