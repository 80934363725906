import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-table-empty',
  templateUrl: './table-empty.component.html',
  styleUrls: ['./table-empty.component.scss'],
})
export class TableEmptyComponent {
  @Input('title') title: string
  @Input('subtitle') subtitle: string
  @Input('button') button: string
  @Input('link') link: string
  @Input('filterUsed') filterUsed: boolean
  @Input('filteredTitle') filteredTitle: string
  @Input('buttonPermission') buttonPermission: { requiredPermission?: string[], editPermission?: string }
}
