import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { isNullOrUndefined } from '../helpers/util.helper'

@Pipe({
  name: 'appInches',
  pure: false,
})
export class AppInchPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(value: number): string {
    if (isNullOrUndefined(value)) {
      return '-'
    }
    return value + ' ' + this.translate.instant('common.inch')
  }

}





