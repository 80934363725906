import { Component, OnInit } from '@angular/core'
import { takeWhile } from 'rxjs/operators'
import { UserModel } from '../../../models'
import { AuthService } from '../../../services/auth.service'
import { BaseComponent } from '../base/base.component'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseComponent implements OnInit {

  public user: UserModel
  public showMenu: boolean

  constructor(private _authService: AuthService) {
    super()
    this.showMenu = false
  }

  ngOnInit() {
    this._authService.user
      .pipe(takeWhile(() => this.alive))
      .subscribe((user) => {
        this.user = user
      })
  }

}
