import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-navbar-sub-item',
  templateUrl: './navbar-sub-item.component.html',
  styleUrls: ['./navbar-sub-item.component.scss'],
})
export class NavbarSubItemComponent {

  @Input('label') label: string
  @Input('link') link: string

}
