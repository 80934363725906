import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'appNumber',
  pure: true,
})
export class AppNumberPipe implements PipeTransform {

  transform(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

}





