import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { ListModel, ProvisionDto, ProvisionFilter, ProvisionModel } from '../models'

@Injectable()
export class ProvisionService {

  public url: string
  public filter: BehaviorSubject<ProvisionFilter> = new BehaviorSubject<ProvisionFilter>(new ProvisionFilter())

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/CalculationInput'
  }

  public getList(): Observable<ListModel<ProvisionModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<ProvisionDto>) => {
          const list = new ListModel<ProvisionModel>()
          list.total = res.total
          list.data = res.data.map((x) => ProvisionDto.toProvisionModel(x))
          return list
        }),
      )
  }

  public getDropdownList(filter: ProvisionFilter): Observable<ListModel<ProvisionModel>> {
    return this._http.get(this.url, { params: filter.toBeFilter() })
      .pipe(
        map((res: ListModel<ProvisionDto>) => {
          const list = new ListModel<ProvisionModel>()
          list.total = res.total
          list.data = res.data.map((x) => ProvisionDto.toProvisionModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<ProvisionModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: ProvisionDto) => ProvisionDto.toProvisionModel(res)),
      )
  }

  public save(provision: ProvisionModel): Observable<ProvisionModel> {
    return this._http.post(this.url, ProvisionDto.fromProvisionModel(provision))
      .pipe(map((res: ProvisionModel) => {
        this._toaster.success(this._translate.instant('messages.created'))
        return new ProvisionModel(res)
      }))
  }

  public update(provision: ProvisionModel): Observable<ProvisionModel> {
    return this._http.put(this.url + '/' + provision.id, ProvisionDto.fromProvisionModel(provision))
      .pipe(map((res: ProvisionModel) => {
        this._toaster.success(this._translate.instant('messages.updated'))
        return new ProvisionModel(res)
      }))
  }

  public delete(provision: ProvisionModel): Observable<boolean> {
    return this._http.delete(this.url + '/' + provision.id).pipe(map(() => {
      this._toaster.success(this._translate.instant('messages.deleted'))
      return true
    }))
  }

  public deactivate(provisionId: number) {
    return this._http.get(this.url + '/Deactivate', { params: { id: provisionId.toString() } }).pipe(
      map(() => true),
    )
  }

  public activate(provisionId: number) {
    return this._http.get(this.url + '/Activate', { params: { id: provisionId.toString() } }).pipe(
      map(() => true),
    )
  }
}
