import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter, takeWhile } from 'rxjs/internal/operators'
import { isNullOrUndefined } from '../../../helpers/util.helper'
import { BreadcrumbModel } from '../../../models'
import { BaseComponent } from '../base/base.component'

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent extends BaseComponent implements OnInit {
  public breadcrumbs: BreadcrumbModel[]

  constructor(private _route: ActivatedRoute,
              private _router: Router) {
    super()
    this.breadcrumbs = []
  }

  ngOnInit() {
    this.breadcrumbs = this.buildBreadCrumb(this._route.root)

    this._router.events
      .pipe(takeWhile(() => this.alive))
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.breadcrumbs = this.buildBreadCrumb(this._route.root))
  }

  private buildBreadCrumb(route: ActivatedRoute, breadcrumbs: BreadcrumbModel[] = []): BreadcrumbModel[] {
    let newBreadcrumbs = breadcrumbs
    let nextUrl = ''

    // create breadcrumb
    if (route.routeConfig && route.routeConfig.data) {
      const label = route.routeConfig.data['breadcrumb']
      const path = this.getPathFromRoot(route.pathFromRoot)

      nextUrl = `${ path }/`
      const breadcrumb = {
        label: label,
        url: nextUrl,
      }

      newBreadcrumbs = [...newBreadcrumbs, breadcrumb]
    }

    // if there are children, continue
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, newBreadcrumbs)
    }

    // return array
    return newBreadcrumbs
  }

  private getPathFromRoot(pathFromRoot: ActivatedRoute[]): string {
    return pathFromRoot
      .map(x => x.routeConfig)
      .filter(x => !isNullOrUndefined(x))
      .map(x => x.path)
      .reduce((acc, value) => {
        let urlSegment = ''
        if (value) {
          urlSegment = '/' + value
        }
        return acc + urlSegment
      }, '')
  }

}
