import { isNullOrUndefined } from '../helpers/util.helper'

export class FilterModel {
  page: number
  pageSize: number
  orderBy: string
  orderDesc: boolean
  searchText: string

  constructor(data?) {
    if (data) {
      this.page = data.page || 1
      this.pageSize = data.pageSize || 20
      this.orderBy = data.orderBy
      this.orderDesc = data.orderDesc
      this.searchText = data.searchText
    } else {
      this.page = 1
      this.pageSize = 20
      this.orderBy = 'Name'
      this.orderDesc = false
      this.searchText = ''
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}
