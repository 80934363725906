import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { distinctUntilChanged } from 'rxjs/internal/operators'
import { TabModel } from '../../../models'

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input('tabs') tabs: TabModel[]

  public form: UntypedFormGroup
  public activeTab: TabModel

  constructor(private _fb: UntypedFormBuilder,
              private _router: Router) {
  }

  ngOnInit() {
    this.activeTab = this.tabs.find(tab => this._router.url.includes(tab.link))

    this.form = this._fb.group({
      'tab': [this.activeTab],
    })

    this.form.get('tab').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(
        (tab: TabModel) => {
          this.activeTab = tab
          this._router.navigate([tab.link])
        },
      )
  }

  changeActiveTab(tab: TabModel) {
    this.activeTab = tab
    this.form.patchValue({ tab: tab }, { onlySelf: true, emitEvent: false })
  }
}
