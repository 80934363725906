import { isNullOrUndefined } from '../helpers/util.helper'
import { FilterModel } from './filter.model'
import { MakeModel } from './car.models'

export class ProductModel {
  id: number
  extProductID: string
  name: string
  name2: string
  description: string
  financingType: FinancingType
  legalForm: LegalFormType
  financingRate: number
  financingRateMin: number
  financingRateMax: number
  sublimitKmAmount: number
  lowerMileageToleranceLimit: number
  upperMileageToleranceLimit: number
  contractualMileageDefault: number
  contractualMileageGap: number
  financingLengthDefault: number
  financingLengthGap: number
  paymentPeriode: ProductPeriodicityType
  upfrontPayments: UpfrontPaymentType
  makes: Array<MakeModel>

  constructor(data) {
    if (data) {
      this.id = data.id
      this.extProductID = data.extProductID
      this.name = data.name
      this.name2 = data.name2
      this.description = data.description
      this.financingType = data.financingType
      this.legalForm = data.legalForm
      this.financingRate = data.financingRate
      this.financingRateMin = data.financingRateMin
      this.financingRateMax = data.financingRateMax
      this.sublimitKmAmount = data.sublimitKmAmount
      this.lowerMileageToleranceLimit = data.lowerMileageToleranceLimit
      this.upperMileageToleranceLimit = data.upperMileageToleranceLimit
      this.contractualMileageDefault = data.contractualMileageDefault
      this.contractualMileageGap = data.contractualMileageGap
      this.financingLengthDefault = data.financingLengthDefault
      this.financingLengthGap = data.financingLengthGap
      this.paymentPeriode = data.paymentPeriode
      this.upfrontPayments = data.upfrontPayments
      this.makes = data.makes ? data.makes.map(m => new MakeModel(m)) : []
    }
  }
}

export enum FinancingType {
  OperativeLeasing = 0,
  undefined = 1 // this is workaround for using toString() on one-value enum
}

export enum LegalFormType {
  LegalEntity = 0,
  Person = 1
}

export enum UpfrontPaymentType {
  Before = 0,
  After = 1
}

export class ProductFilter extends FilterModel {
  financingType: FinancingType
  legalForm: LegalFormType

  constructor(data?: ProductFilter) {
    if (data) {
      super(data)
      this.financingType = data.financingType
      this.legalForm = data.legalForm
    } else {
      super()
      this.orderBy = 'Name'
      this.financingType = null
      this.legalForm = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.financingType || !!this.legalForm
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      financingType: this.financingType ? this.financingType.toString() : null,
      legalForm: this.legalForm ? this.legalForm.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}

export enum ProductPeriodicityType {
  Year = 1,
  HalfYear = 2,
  Quarter = 4,
  Month = 12,
}
