import { NgIf, NgIfContext } from '@angular/common'
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { Subscription } from 'rxjs'
import { AuthGuard } from '../services/auth-guard.service'
import { AuthService } from '../services/auth.service'

@Directive({
  selector: '[appPermissionRestrict]',
})
export class PermissionRestrictDirective extends NgIf implements OnInit, OnDestroy {
  private requiredPermission: string[]
  private editPermission: string
  private negation: boolean
  private loginChange$: Subscription

  constructor(viewContainer: ViewContainerRef,
              templateRef: TemplateRef<NgIfContext>,
              private _authService: AuthService,
              private _authGuard: AuthGuard) {
    super(viewContainer, templateRef)
  }

  @Input() set appPermissionRestrict(permissions: { requiredPermission?: string[], editPermission?: string, negation?: boolean }) {
    this.requiredPermission = permissions ? permissions.requiredPermission : null
    this.editPermission = permissions ? permissions.editPermission : null
    this.negation = permissions ? permissions.negation : false
    this.ngIf = this.compute()
  }

  ngOnInit(): void {
    this.loginChange$ = this._authService.user.subscribe(() => {
      this.ngIf = this.compute()
    })
  }

  ngOnDestroy(): void {
    this.loginChange$.unsubscribe()
  }

  private compute(): boolean {
    let hasAccess = true
    if (this.requiredPermission) {
      hasAccess = this._authGuard.hasAccess(this.requiredPermission)
    }

    let hasEditPermission = true
    if (this.editPermission) {
      hasEditPermission = this._authGuard.hasEditPermission(this.editPermission)
    }

    let result = hasAccess && hasEditPermission
    if (this.negation) {
      result = !result
    }

    return result
  }
}
