import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { takeWhile } from 'rxjs/internal/operators'
import { UserModel } from '../../../../models/user.model'
import { AuthService } from '../../../../services/auth.service'
import { BaseComponent } from '../../base/base.component'

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent extends BaseComponent implements OnInit {

  public user: UserModel
  public opened: boolean
  @Output() itemClicked = new EventEmitter<boolean>()

  constructor(private _authService: AuthService) {
    super()
  }

  ngOnInit() {
    this._authService.user
      .pipe(takeWhile(() => this.alive))
      .subscribe((user) => this.user = user)
  }

  public logout() {
    this._authService.logout()
  }

}
