import { isNullOrUndefined } from '../helpers/util.helper'
import { FilterModel } from './filter.model'

export class TireModel {
  id: number
  vendorMake: string // show in table
  season: Season // show in table
  width: number // show in table
  profile: number // show in table
  diameter: number // show in table
  pattern: string
  load_Index: string
  speed_Index: string
  comment: string
  priceExclVAT: number // show in table
  cargo: boolean
  category: TireCategory // show in table

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.vendorMake = data.vendorMake
      this.season = data.season
      this.width = data.width
      this.profile = data.profile
      this.diameter = data.diameter
      this.pattern = data.pattern
      this.load_Index = data.load_Index
      this.speed_Index = data.speed_Index
      this.comment = data.comment
      this.priceExclVAT = data.priceExclVAT
      this.cargo = data.cargo
      this.category = data.category
    }
  }
}

export class TireFilterModel extends FilterModel {
  season: Season
  width: number
  profile: number
  diameter: number
  category: TireCategory
  runflat: boolean
  xl: boolean
  type: TireType

  constructor(data?: TireFilterModel) {
    if (data) {
      super(data)
      this.season = data.season
      this.width = data.width
      this.profile = data.profile
      this.diameter = data.diameter
      this.category = data.category
      this.runflat = data.runflat
      this. xl = data.xl
      this.type = data.type
    } else {
      super()
      this.orderBy = 'Width'
      this.season = null
      this.width = null
      this.profile = null
      this.diameter = null
      this.category = null
      this.runflat = null
      this.xl = null
      this.type = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText ||
      !isNullOrUndefined(this.season) ||
      !isNullOrUndefined(this.width) ||
      !isNullOrUndefined(this.profile) ||
      !isNullOrUndefined(this.diameter) ||
      !isNullOrUndefined(this.category) ||
      !isNullOrUndefined(this.runflat) ||
      !isNullOrUndefined(this.xl) ||
      !isNullOrUndefined(this.type)
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      season: isNullOrUndefined(this.season) ? null : this.season.toString(),
      width: isNullOrUndefined(this.width) ? null : this.width.toString(),
      profile: isNullOrUndefined(this.profile) ? null : this.profile.toString(),
      diameter: isNullOrUndefined(this.diameter) ? null : this.diameter.toString(),
      category: isNullOrUndefined(this.category) ? null : this.category.toString(),
      runflat: isNullOrUndefined(this.runflat) ? null : this.runflat.toString(),
      xl: isNullOrUndefined(this.xl) ? null : this.xl.toString(),
      type: isNullOrUndefined(this.type) ? null : this.type.toString(),
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}

export class RimModel {
  id: number
  make: string
  type: string
  design: string
  width: number // show in table
  diameter: number // show in table
  comment: string
  priceExclVAT: number // show in table

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.make = data.make
      this.type = data.type
      this.design = data.design
      this.width = data.width
      this.diameter = data.diameter
      this.comment = data.comment
      this.priceExclVAT = data.priceExclVAT
    }
  }
}

export class RimFilterModel extends FilterModel {
  width: number
  diameter: number

  constructor(data?: TireFilterModel) {
    if (data) {
      super(data)
      this.width = data.width
      this.diameter = data.diameter
    } else {
      super()
      this.orderBy = 'Width'
      this.width = null
      this.diameter = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText ||
      !isNullOrUndefined(this.width) ||
      !isNullOrUndefined(this.diameter)
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      width: isNullOrUndefined(this.width) ? null : this.width.toString(),
      diameter: isNullOrUndefined(this.diameter) ? null : this.diameter.toString(),
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}

export class TireStorageModel {
  id: number
  type: TireChangeType // show in table
  rimDiameterFrom: number // show in table
  rimDiameterTo: number // show in table
  priceExclVAT: number // show in table

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.type = data.type
      this.rimDiameterFrom = data.rimDiameterFrom
      this.rimDiameterTo = data.rimDiameterTo
      this.priceExclVAT = data.priceExclVAT
    }
  }
}

export class TireStorageFilterModel extends FilterModel {
  type: TireChangeType
  diameter: number

  constructor(data?: TireStorageFilterModel) {
    if (data) {
      super(data)
      this.type = data.type
      this.diameter = data.diameter
    } else {
      super()
      this.orderBy = 'RimDiameterFrom'
      this.type = null
      this.diameter = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText ||
      !isNullOrUndefined(this.type) ||
      !isNullOrUndefined(this.diameter)
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      type: isNullOrUndefined(this.type) ? null : this.type.toString(),
      diameter: isNullOrUndefined(this.diameter) ? null : this.diameter.toString(),
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}

export class TireChangeModel {
  id: number
  category: TireChangeCategory // show in table
  rimDiameterFrom: number // show in table
  rimDiameterTo: number // show in table
  tireChangeType: TireChangeType // show in table
  priceExclVAT: number // show in table

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.category = data.category
      this.rimDiameterFrom = data.rimDiameterFrom
      this.rimDiameterTo = data.rimDiameterTo
      this.tireChangeType = data.tireChangeType
      this.priceExclVAT = data.priceExclVAT
    }
  }
}

export class TireChangeFilterModel extends FilterModel {
  category: TireChangeCategory
  diameter: number
  tireChangeType: TireChangeType

  constructor(data?: TireChangeFilterModel) {
    if (data) {
      super(data)
      this.category = data.category
      this.diameter = data.diameter
      this.tireChangeType = data.tireChangeType
    } else {
      super()
      this.orderBy = 'RimDiameterFrom'
      this.category = null
      this.diameter = null
      this.tireChangeType = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText ||
      !isNullOrUndefined(this.category) ||
      !isNullOrUndefined(this.diameter) ||
      !isNullOrUndefined(this.tireChangeType)
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      category: isNullOrUndefined(this.category) ? null : this.category.toString(),
      diameter: isNullOrUndefined(this.diameter) ? null : this.diameter.toString(),
      tireChangeType: isNullOrUndefined(this.tireChangeType) ? null : this.tireChangeType.toString(),
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}

export enum Season {
  AllSeason = 0,
  Summer = 1,
  Winter = 2
}

export enum TirePosition {
  FrontRear = 0,
  Front = 1,
  Rear = 2
}

export enum TireChangeType {
  TireChange = 0,
  WheelChange = 1
}

export enum TireCategory {
  Basic = 0,
  Medium = 1,
  Premium = 2
}

export enum TireChangeCategory {
  Personal = 0,
  TPMS = 1,
  RunFlat_Personal = 2,
  RunFlat_TPMS = 3,
  SUV = 4,
  VAN = 5,
  RunFlat_SUV = 6
}

export enum TireType {
  Personal = 0,
  SUV = 1,
  VAN = 2
}

