import { Routes } from '@angular/router'
import {
  CanManageCatalogue,
  CanManageInsurances,
  CanManageProductsServices,
  CanManageProvisions,
  CanManageSettings,
  CanManageUsersOrgUnits,
} from './models'
import { MissingPermissionComponent } from './modules/theme/missing-permission/missing-permission.component'
import { AuthGuard } from './services/auth-guard.service'

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'cars',
  },
  {
    path: 'public',
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'cars',
    loadChildren: () => import('./modules/cars/cars.module').then(m => m.CarsModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      breadcrumb: 'breadcrumbs.cars.title',
      requiredPermission: [CanManageCatalogue],
    },
  },
  {
    path: 'insurance',
    loadChildren: () => import('./modules/insurance/insurance.module').then(m => m.InsuranceModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      breadcrumb: 'breadcrumbs.insurance.title',
      requiredPermission: [CanManageInsurances],
    },
  },
  {
    path: 'users-organizations',
    loadChildren: () => import('./modules/users-organizations/users-organizations.module').then(m => m.UsersOrganizationsModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      breadcrumb: 'breadcrumbs.usersOrganizations',
      requiredPermission: [CanManageUsersOrgUnits],
    },
  },
  {
    path: 'product-configuration',
    loadChildren: () => import('./modules/product-configuration/product-configuration.module').then(m => m.ProductConfigurationModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      breadcrumb: 'breadcrumbs.productConfiguration.title',
      requiredPermission: [CanManageProductsServices],
    },
  },
  {
    path: 'provision',
    loadChildren: () => import('./modules/provision/provision.module').then(m => m.ProvisionModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      breadcrumb: 'breadcrumbs.provision.title',
      requiredPermission: [CanManageProvisions],
    },
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      breadcrumb: 'layout.menu.settings',
      requiredPermission: [CanManageSettings],
    },
  },
  {
    path: 'user-settings',
    loadChildren: () => import('./modules/user-settings/user-settings.module').then(m => m.UserSettingsModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      breadcrumb: 'breadcrumbs.userSettings.title',
    },
  },
  {
    path: 'missing-permission',
    component: MissingPermissionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'cars',
  },
]
