import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Observable } from 'rxjs'
import { Subject } from 'rxjs/internal/Subject'
import { map } from 'rxjs/operators'

import { EditPermissions, LoginResponse, UserModel } from '../models'
import { PreferedLocalization } from '../models/preferedLocalization.enum'
import { TokenService } from './token.service'

@Injectable()
export class AuthService {

  public url: string
  public redirectUrl: string
  public logoutRedirect: string
  public user: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null)
  public editPermissions: EditPermissions
  public inactivityLogout: boolean
  public resetInactivity: Subject<boolean> = new Subject()
  private inactivityTimer = null
  private inactivityTimeToLogout = 60000 * 60 //60 minutes

  constructor(private _http: HttpClient,
              private _tokenService: TokenService,
              private _router: Router) {
    this.url = '/api/Auth'
    this.logoutRedirect = '/public/login'
    this._tokenService.refreshToken.subscribe(() => this.refreshToken())
    this.resetInactivity.subscribe(() => this.setInactivityTimer())
    this.editPermissions = new EditPermissions({
      carsCatalogue: false,
      carsParameters: false,
      productConfigurationPneuservis: false,
    })
  }

  public login(data): Observable<LoginResponse> {
    return this._http.post(this.url + '/Login', data)
      .pipe(
        map((response: LoginResponse) => {
          if (response.token) {
            this._tokenService.saveToken(response.token)
            this._tokenService.setTokenRefreshTimer()
            this.user.next(null)
          }
          return response
        }),
      )
  }

  public refreshToken(): Observable<boolean> {
    return this._http.get(this.url + '/Refresh')
      .pipe(
        map((response: { token: string }) => {
          localStorage.setItem('token', response.token)
          this._tokenService.setTokenRefreshTimer()
          return true
        }),
      )
  }

  public logout(inactivityLogout?: boolean) {
    localStorage.removeItem('token')
    this.user.next(null)
    this.inactivityLogout = !!inactivityLogout
    this._router.navigate([this.logoutRedirect])
  }

  public getUser(): Observable<UserModel> {
    return this._http.get<UserModel>(this.url + '/GetLoggedUser')
      .pipe(map((user: UserModel) => {
        this.user.next(new UserModel(user))
        this.setInactivityTimer()
        return new UserModel(user)
      }))
  }

  public updateUserLanguage(language: PreferedLocalization) {
    const updatedUser = new UserModel(this.user.value)
    updatedUser.preferredLocalization = language
    this.user.next(updatedUser)
  }

  private setInactivityTimer() {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer)
    }
    this.inactivityTimer = setTimeout(() => this.logout(true), this.inactivityTimeToLogout)
  }
}
