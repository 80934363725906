import { Pipe, PipeTransform } from '@angular/core'
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'

@Pipe({
  name: 'asDate',
  pure: false,
})
export class DatePipe implements PipeTransform {

  transform(value: NgbDateStruct): string {
    if (value) {
      return value.day + '.' + value.month + '.' + value.year
    }
    return ''
  }

}
