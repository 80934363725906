import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { FilterModel, ListModel, RefiModel } from '../models'
import { CommonDatepickerFunctions } from './common-datepicker-functions'

@Injectable()
export class RefiService {

  public url: string
  public filter: BehaviorSubject<FilterModel> = new BehaviorSubject(new FilterModel())

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/REFIRate'
  }

  public getList(): Observable<ListModel<RefiModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<RefiModel>) => {
          const list = new ListModel<RefiModel>()
          list.total = res.total
          res.data.forEach((x) => {
            x.validFrom = CommonDatepickerFunctions.toNgbDateStruct(x.validFrom)
            x.validTo = CommonDatepickerFunctions.toNgbDateStruct(x.validTo)
          })
          list.data = res.data.map((x) => new RefiModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<RefiModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(map((res: RefiModel) => {
        res.validFrom = CommonDatepickerFunctions.toNgbDateStruct(res.validFrom)
        res.validTo = CommonDatepickerFunctions.toNgbDateStruct(res.validTo)
        return new RefiModel(res)
      }))
  }

  public save(refi: RefiModel): Observable<boolean> {
    const entity: any = refi
    entity.validFrom = CommonDatepickerFunctions.getDataDate(refi.validFrom)
    entity.validTo = CommonDatepickerFunctions.getDataDate(refi.validTo)

    return this._http.post(this.url, entity)
      .pipe(map(() => {
        this._toaster.success(this._translate.instant('messages.created'))
        return true
      }))
  }

  public update(refi: RefiModel, refiId: Number): Observable<boolean> {
    const entity: any = refi
    entity.validFrom = CommonDatepickerFunctions.getDataDate(refi.validFrom)
    entity.validTo = CommonDatepickerFunctions.getDataDate(refi.validTo)

    return this._http.put(this.url + '/' + refiId, entity)
      .pipe(map(() => {
        this._toaster.success(this._translate.instant('messages.updated'))
        return true
      }))
  }

  public delete(refiId: number): Observable<boolean> {
    return this._http.delete(this.url + '/' + refiId)
      .pipe(map(() => {
        this._toaster.success(this._translate.instant('messages.deleted'))
        return true
      }))
  }

}
