import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import {
  ListModel,
  RimFilterModel,
  RimModel,
  TireChangeFilterModel,
  TireChangeModel,
  TireFilterModel,
  TireModel,
  TireStorageFilterModel,
  TireStorageModel,
} from '../models'

@Injectable()
export class PneuService {

  public url: string
  public tireFilter: BehaviorSubject<TireFilterModel> = new BehaviorSubject<TireFilterModel>(new TireFilterModel())
  public tireChangeFilter: BehaviorSubject<TireChangeFilterModel> = new BehaviorSubject<TireChangeFilterModel>(new TireChangeFilterModel())
  public tireStorageFilter: BehaviorSubject<TireStorageFilterModel> = new BehaviorSubject<TireStorageFilterModel>(new TireStorageFilterModel())
  public rimFilter: BehaviorSubject<RimFilterModel> = new BehaviorSubject<RimFilterModel>(new RimFilterModel())

  constructor(private _http: HttpClient) {
    this.url = '/api/administration/PneuService/'
  }

  public getTireList(): Observable<ListModel<TireModel>> {
    return this._http.get(this.url + 'Tires', { params: this.tireFilter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<TireModel>) => {
          const list = new ListModel<TireModel>()
          list.total = res.total
          list.data = res.data.map((x) => new TireModel(x))
          return list
        }),
      )
  }

  public getTireChangeList(): Observable<ListModel<TireChangeModel>> {
    return this._http.get(this.url + 'Change', { params: this.tireChangeFilter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<TireChangeModel>) => {
          const list = new ListModel<TireChangeModel>()
          list.total = res.total
          list.data = res.data.map((x) => new TireChangeModel(x))
          return list
        }),
      )
  }

  public getTireStorageList(): Observable<ListModel<TireStorageModel>> {
    return this._http.get(this.url + 'Storage', { params: this.tireStorageFilter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<TireStorageModel>) => {
          const list = new ListModel<TireStorageModel>()
          list.total = res.total
          list.data = res.data.map((x) => new TireStorageModel(x))
          return list
        }),
      )
  }

  public getRimList(): Observable<ListModel<RimModel>> {
    return this._http.get(this.url + 'Rims', { params: this.rimFilter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<RimModel>) => {
          const list = new ListModel<RimModel>()
          list.total = res.total
          list.data = res.data.map((x) => new RimModel(x))
          return list
        }),
      )
  }
}
