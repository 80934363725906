<div class="modal-header">
  <div [translate]="title" class="modal-title"></div>
  <button (click)="activeModal.dismiss('close')" aria-label="Close" class="btn close" type="button">
    <app-icon icon="close" size="sm"></app-icon>
  </button>
</div>
<div class="modal-body" fxLayout fxLayoutAlign="space-evenly center">
  <button (click)="activeModal.close('deny')" [translate]="denyText" class="btn btn-link"></button>
  <button (click)="activeModal.close('confirm')" [translate]="confirmText" class="btn btn-primary"></button>
</div>
