import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { CarListFilterModel, CatalogueModel, ListModel } from '../../models'

@Injectable()
export class CatalogueService {

  public url: string
  public filter: BehaviorSubject<CarListFilterModel> = new BehaviorSubject<CarListFilterModel>(new CarListFilterModel())

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/Catalogue'
  }


  public getList(): Observable<ListModel<CatalogueModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<CatalogueModel>) => {
          const list = new ListModel<CatalogueModel>()
          list.total = res.total
          list.data = res.data.map((x) => new CatalogueModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<CatalogueModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: CatalogueModel) => new CatalogueModel(res)),
      )
  }

  public save(catalogue: CatalogueModel): Observable<CatalogueModel> {
    return this._http.post(this.url, catalogue)
      .pipe(map((res: CatalogueModel) => {
        this._toaster.success(this._translate.instant('messages.created'))
        return new CatalogueModel(res)
      }))
  }

  public update(catalogue: CatalogueModel): Observable<CatalogueModel> {
    return this._http.put(this.url + '/' + catalogue.id, catalogue)
      .pipe(map((res: CatalogueModel) => {
        this._toaster.success(this._translate.instant('messages.updated'))
        return new CatalogueModel(res)
      }))
  }

  public delete(id: number): Observable<boolean> {
    return this._http.delete(this.url + '/' + id).pipe(
      map(() => {
        this._toaster.success(this._translate.instant('messages.deleted'))
        return true
      }))
  }
}
