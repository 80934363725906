import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { CarType } from '../car.models'

export class RoadTaxModel {
  id: number
  name: string
  description: string
  carType: CarType
  engineVolumeFrom: number
  engineVolumeTo: number
  totalWeightFrom: number
  totalWeightTo: number
  validFrom: NgbDateStruct
  validTo: NgbDateStruct
  yearPriceExclVAT: number

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.description = data.description
      this.carType = data.carType
      this.engineVolumeFrom = data.engineVolumeFrom
      this.engineVolumeTo = data.engineVolumeTo
      this.totalWeightFrom = data.totalWeightFrom
      this.totalWeightTo = data.totalWeightTo
      this.validFrom = data.validFrom
      this.validTo = data.validTo
      this.yearPriceExclVAT = data.yearPriceExclVAT
    }
  }
}
