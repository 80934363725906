import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core'
import { ControlValueAccessor, DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

export const SPLITTER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumbersOnlyDirective),
  multi: true,
}

@Directive({
  selector: '[appNumbersOnly]',
  providers: [SPLITTER_VALUE_ACCESSOR],
})
export class NumbersOnlyDirective extends DefaultValueAccessor implements ControlValueAccessor {

  public onChange
  public onTouched

  constructor(private renderer: Renderer2,
              private element: ElementRef) {
    super(renderer, element, true)
  }

  @HostListener('input', ['$event.target.value'])
  input(value) {
    const numbers = value.replace(/[^0-9]/g, '')
    this.onChange(numbers)
    this.writeValue(numbers)
  }

  @HostListener('focus')
  focus() {
    this.onTouched()
  }

  writeValue(value: any): void {
    const element = this.element.nativeElement
    this.renderer.setProperty(element, 'value', value)
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  setDisabledState(isDisabled: boolean): void {
    const element = this.element.nativeElement
    if (isDisabled) {
      this.renderer.setAttribute(element, 'disabled', isDisabled.toString())
    } else {
      this.renderer.removeAttribute(element, 'disabled')
    }
  }
}
