export class PriceListAdministrationFeeModel {
  priceMax: number
  priceMin: number
  priceExclVAT: number

  constructor(data) {
    this.priceMax = data.priceMax
    this.priceMin = data.priceMin
    this.priceExclVAT = data.priceExclVAT
  }
}
