import { isNullOrUndefined } from '../helpers/util.helper'
import { ClassModel } from './car.models'
import { FilterModel } from './filter.model'

export class EquipmentEntryModel {
  id: number
  name: string
  description: string

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.description = data.description
    }
  }
}

export class EquipmentIntervalModel {
  id: number
  minEquipmentCount: number
  maxEquipmentCount: number
  residValueImpactPerc: number

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.minEquipmentCount = data.minEquipmentCount
      this.maxEquipmentCount = data.maxEquipmentCount
      this.residValueImpactPerc = data.residValueImpactPerc
    }
  }
}

export class EquipmentGroupModel {
  id: number
  name: string
  carListPriceExclVATFrom: number
  carListPriceExclVATTo: number
  carClass: ClassModel
  groupEntries: EquipmentEntryModel[]
  intervals: EquipmentIntervalModel[]

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.carListPriceExclVATFrom = data.carListPriceExclVATFrom
      this.carListPriceExclVATTo = data.carListPriceExclVATTo
      this.carClass = new ClassModel(data.carClass)
      this.groupEntries = data.groupEntries ? data.groupEntries.map((x) => new EquipmentEntryModel(x)) : []
      this.intervals = data.intervals ? data.intervals.map((x) => new EquipmentIntervalModel(x)) : []
    }
  }
}


export class EquipmentGroupFilterModel extends FilterModel {
  carClass: ClassModel

  constructor(data?: EquipmentGroupFilterModel) {
    if (data) {
      super(data)
      this.carClass = data.carClass
    } else {
      super()
      this.orderBy = 'Name'
      this.carClass = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.carClass
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      carClassId: this.carClass ? this.carClass.id.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}
