import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FilterModel } from '../../../models'

@Component({
  selector: 'app-table-header-item',
  templateUrl: './table-header-item.component.html',
  styleUrls: ['./table-header-item.component.scss'],
})
export class TableHeaderItemComponent {

  @Input('filter') filter: FilterModel
  @Input('key') key: string
  @Input('label') label: string
  @Input() align: 'start' | 'end' = 'start'
  @Output() onClick = new EventEmitter<string>()
}
