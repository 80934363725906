import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { InsuranceRestrictionFilterModel, InsuranceRestrictionModel, ListModel } from '../../models'

@Injectable()
export class InsuranceRestrictionService {

  public url: string
  public filter: BehaviorSubject<InsuranceRestrictionFilterModel>

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/InsuranceRestriction'
    this.filter = new BehaviorSubject<InsuranceRestrictionFilterModel>(new InsuranceRestrictionFilterModel())
  }

  public getList(): Observable<ListModel<InsuranceRestrictionModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<InsuranceRestrictionModel>) => {
          const list = new ListModel<InsuranceRestrictionModel>()
          list.total = res.total
          list.data = res.data.map((x) => new InsuranceRestrictionModel(x))
          return list
        }),
      )
  }

  public getDropdownList(filter: InsuranceRestrictionFilterModel): Observable<ListModel<InsuranceRestrictionModel>> {
    return this._http.get(this.url, { params: filter.toBeFilter() })
      .pipe(
        map((res: ListModel<InsuranceRestrictionModel>) => {
          const list = new ListModel<InsuranceRestrictionModel>()
          list.total = res.total
          list.data = res.data.map((x) => new InsuranceRestrictionModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<InsuranceRestrictionModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: InsuranceRestrictionModel) => {
          return new InsuranceRestrictionModel(res)
        }),
      )
  }

  public save(item: InsuranceRestrictionModel): Observable<InsuranceRestrictionModel> {
    return this._http.post(this.url, item)
      .pipe(
        map((res: InsuranceRestrictionModel) => {
          this._toaster.success(this._translate.instant('messages.created'))
          return new InsuranceRestrictionModel(res)
        }),
      )
  }

  public update(id: Number, item: InsuranceRestrictionModel): Observable<InsuranceRestrictionModel> {
    return this._http.put(this.url + '/' + id, item)
      .pipe(
        map((res: InsuranceRestrictionModel) => {
          this._toaster.success(this._translate.instant('messages.updated'))
          return new InsuranceRestrictionModel(res)
        }),
      )
  }

  public delete(id: number): Observable<boolean> {
    return this._http.delete(this.url + '/' + id).pipe(
      map(() => {
        this._toaster.success(this._translate.instant('messages.deleted'))
        return true
      }),
    )
  }
}
