import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ClaimModel, FilterModel, ListModel, UserRoleModel } from '../models'

@Injectable()
export class RoleService {

  public url: string
  public filter: BehaviorSubject<FilterModel> = new BehaviorSubject<FilterModel>(new FilterModel())

  constructor(private _http: HttpClient) {
    this.url = '/api/administration/Role'
  }

  public getList(): Observable<ListModel<UserRoleModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<UserRoleModel>) => {
          const list = new ListModel<UserRoleModel>()
          list.total = res.total
          list.data = res.data.map((x) => new UserRoleModel(x))
          return list
        }),
      )
  }

  public getRole(id: number): Observable<UserRoleModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: UserRoleModel) => new UserRoleModel(res)),
      )
  }

  public update(role: UserRoleModel): Observable<UserRoleModel> {
    return this._http.put(this.url, role, { params: { id: role.id.toString() } })
      .pipe(
        map((res: UserRoleModel) => new UserRoleModel(res)),
      )
  }

  public save(role: UserRoleModel): Observable<UserRoleModel> {
    return this._http.post(this.url, role)
      .pipe(
        map((res: UserRoleModel) => new UserRoleModel(res)),
      )
  }

  public delete(id: number): Observable<any> {
    return this._http.delete(this.url, { params: { id: id.toString() } })
  }

  public getClaims(searchText: string): Observable<ListModel<ClaimModel>> {
    return this._http.get('api/administration/Claim', { params: { searchText: searchText } })
      .pipe(
        map((res: ListModel<ClaimModel>) => {
          const list = new ListModel<ClaimModel>()
          list.total = res.total
          list.data = res.data.map((x) => new ClaimModel(x))
          return list
        }),
      )
  }
}
