import { isNullOrUndefined } from '../../helpers/util.helper'
import { FilterModel } from '../filter.model'

export class PriceListRegistrationFeeModel {
  id: number
  type: RegistrationFeeType
  rate: number
  enginePowerFrom: number
  enginePowerTo: number
  includeInInputPrice: boolean

  constructor(data?) {
    if (data) {
      this.id = data.id
      this.type = data.type
      this.rate = data.rate
      this.enginePowerFrom = data.enginePowerFrom
      this.enginePowerTo = data.enginePowerTo
      this.includeInInputPrice = data.includeInInputPrice
    }
  }
}

export enum RegistrationFeeType {
  Commision = 0,
  Register = 1,
  LogoutEvidence = 2,
  RegisterMark = 3
}

export class RegistrationFeeFilter extends FilterModel {
  type: RegistrationFeeType

  constructor(data?: RegistrationFeeFilter) {
    if (data) {
      super(data)
      this.type = data.type
    } else {
      super()
      this.orderBy = 'EnginePowerFrom'
      this.type = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.type
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      type: this.type ? this.type.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}
