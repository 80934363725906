import { isNullOrUndefined } from '../helpers/util.helper'
import { MakeModel } from './car.models'
import { FilterModel } from './filter.model'
import { OrgUnitModel } from './org-unit.model'

export class ProvisionModel {
  id: number
  amount: number
  countToRepayments: boolean
  isPercentage: boolean
  externalId: string
  name: string
  provisionType: ProvisionType
  carMake: MakeModel
  isVisible: boolean
  defaultAmount: number
  minimalAmount: number
  maximalAmount: number
  orgStructureId: OrgUnitModel
  provisionKind: ProvisionKindType
  provisionCalculationBasis: ProvisionCalculationBasis
  notEditableInCalculation: boolean
  notVisibleInCalculation: boolean

  constructor(data) {
    if (data) {
      this.id = data.id
      this.amount = data.amount
      this.countToRepayments = data.countToRepayments
      this.isPercentage = data.isPercentage
      this.externalId = data.externalId
      this.name = data.name
      this.provisionType = data.provisionType
      this.carMake = data.carMake ? new MakeModel(data.carMake) : null
      this.isVisible = data.isVisible
      this.defaultAmount = data.defaultAmount || data.amount
      this.minimalAmount = data.minimalAmount
      this.maximalAmount = data.maximalAmount
      this.orgStructureId = data.orgStructureId
      this.provisionKind = data.provisionKind
      this.provisionCalculationBasis = data.provisionCalculationBasis
      this.notEditableInCalculation = data.notEditableInCalculation
      this.notVisibleInCalculation = data.notVisibleInCalculation
    }
  }
}

export class ProvisionDto {
  id: number
  amount: number
  countToRepayments: boolean
  isPercentage: boolean
  externalId: string
  name: string
  provisionType: ProvisionType
  carMake: MakeModel
  isVisible: boolean
  defaultAmount: number
  minimalAmount: number
  maximalAmount: number
  orgStructureIds: OrgUnitModel[]
  provisionKind: ProvisionKindType
  provisionCalculationBasis: ProvisionCalculationBasis
  notEditableInCalculation: boolean
  notVisibleInCalculation: boolean


  public static fromProvisionModel(data: ProvisionModel): ProvisionDto {
    console.log(data)
    return {
      id: data.id,
      amount: data.amount,
      countToRepayments: data.countToRepayments,
      isPercentage: data.isPercentage,
      externalId: data.externalId,
      name: data.name,
      provisionType: data.provisionType,
      carMake: data.carMake ? new MakeModel(data.carMake) : null,
      isVisible: data.isVisible,
      defaultAmount: data.defaultAmount || data.amount,
      minimalAmount: data.minimalAmount,
      maximalAmount: data.maximalAmount,
      orgStructureIds: data.orgStructureId ? [data.orgStructureId] : null,
      provisionKind: data.provisionKind,
      provisionCalculationBasis: data.provisionCalculationBasis,
      notEditableInCalculation: data.notEditableInCalculation,
      notVisibleInCalculation: data.notVisibleInCalculation
    } as ProvisionDto
  }

  public static toProvisionModel(data: ProvisionDto): ProvisionModel {
    console.log(data)
    return new ProvisionModel({
      id: data.id,
      amount: data.amount,
      countToRepayments: data.countToRepayments,
      isPercentage: data.isPercentage,
      externalId: data.externalId,
      name: data.name,
      provisionType: data.provisionType,
      carMake: data.carMake ? new MakeModel(data.carMake) : null,
      isVisible: data.isVisible,
      defaultAmount: data.defaultAmount || data.amount,
      minimalAmount: data.minimalAmount,
      maximalAmount: data.maximalAmount,
      orgStructureId: data.orgStructureIds ? data.orgStructureIds[0] : null,
      provisionKind: data.provisionKind,
      provisionCalculationBasis: data.provisionCalculationBasis,
      notEditableInCalculation: data.notEditableInCalculation,
      notVisibleInCalculation: data.notVisibleInCalculation
    })
  }
}

export enum ProvisionType {
  Cost = 0,
  Revenue = 1
}

export enum ProvisionKindType {
  Dealer = 0,
  Import = 1
}

export enum ProvisionCalculationBasis {
  PriceExclVat = 0,
  PriceExclVatAfterDiscount = 1
}

export class ProvisionFilter extends FilterModel {
  orgUnit: OrgUnitModel

  constructor(data?: ProvisionFilter) {
    if (data) {
      super(data)
      this.orgUnit = data.orgUnit
    } else {
      super()
      this.orderBy = 'Name'
      this.orgUnit = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.orgUnit
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      DealerId: this.orgUnit ? this.orgUnit.id.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}
