<div (click)="opened = !opened" class="name-tile" fxLayout fxLayoutAlign="space-between center">
  <strong>{{user?.username}}</strong>
  <app-icon [rotate]="opened ? 0 : 180" icon="chevron-up" size="xs"></app-icon>
</div>

<div *ngIf="opened" class="user-menu">
  <a (click)="itemClicked.emit()" class="user-menu-item" routerLink="/user-settings"
     translate="layout.userSettings"></a>
  <a (click)="logout()" class="user-menu-item"><strong translate="layout.logout"></strong></a>
</div>
