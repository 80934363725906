import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { FilterModel, ListModel, MakeModel } from '../../models'

@Injectable()
export class MakeService {

  public url: string
  public filter: BehaviorSubject<FilterModel>

  constructor(private _http: HttpClient,
              private _toaster: ToastrService,
              private _translate: TranslateService) {
    this.url = '/api/administration/Make'
    this.filter = new BehaviorSubject<FilterModel>(new FilterModel())
  }

  public getList(): Observable<ListModel<MakeModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<MakeModel>) => {
          const list = new ListModel<MakeModel>()
          list.total = res.total
          list.data = res.data.map((x) => new MakeModel(x))
          return list
        }),
      )
  }

  public getDropdownList(filter: FilterModel): Observable<ListModel<MakeModel>> {
    return this._http.get(this.url, { params: filter.toBeFilter() })
      .pipe(
        map((res: ListModel<MakeModel>) => {
          const list = new ListModel<MakeModel>()
          list.total = res.total
          list.data = res.data.map((x) => new MakeModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<MakeModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: MakeModel) => new MakeModel(res)),
      )
  }

  public save(make: MakeModel): Observable<MakeModel> {
    return this._http.post(this.url, make)
      .pipe(map((res: MakeModel) => {
        this._toaster.success(this._translate.instant('messages.created'))
        return new MakeModel(res)
      }))
  }

  public update(make: MakeModel): Observable<MakeModel> {
    return this._http.put(this.url + '/' + make.id, make)
      .pipe(map((res: MakeModel) => {
        this._toaster.success(this._translate.instant('messages.updated'))
        return new MakeModel(res)
      }))
  }

  public delete(makeId: number): Observable<boolean> {
    return this._http.delete(this.url + '/' + makeId).pipe(
      map(() => {
        this._toaster.success(this._translate.instant('messages.deleted'))
        return true
      }))
  }
}
