import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {

  @Input() icon: string
  @Input() size: 'xs' | 'sm' | 'md' | 'lg'
  @Input() rotate: number

  constructor() {
  }

  get baseClassName(): string {
    let className = 'icon icon-' + this.icon

    if (this.size) {
      className += ' ' + 'icon-' + this.size
    }

    return className

  }

  ngOnInit() {

    if (!this.rotate) {
      this.rotate = 0
    }
  }

}
