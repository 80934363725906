import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Pipe({
  name: 'appMonths',
  pure: false,
})
export class AppMonthsPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(value: number): string {
    return value + ' ' + this.translate.instant('common.months')
  }

}





