import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core'

@Directive({
  selector: '[appOnOutsideClick]',
})
export class OnOutsideClickDirective {

  @Output() appOnOutsideClick = new EventEmitter()

  constructor(private _eref: ElementRef) {
  }

  @HostListener('document:click', ['$event']) onClick($event) {
    const datepickersOpened = this._eref.nativeElement.getElementsByTagName('ngb-datepicker')

    if (!this._eref.nativeElement.contains($event.target) && datepickersOpened.length) {
      $event.preventDefault()
      $event.stopPropagation()
      this.appOnOutsideClick.emit()
    }
  }

}
