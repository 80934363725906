import { Component } from '@angular/core'
import { NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { distinctUntilChanged } from 'rxjs/operators'
import { PreferedLocalization } from './models/preferedLocalization.enum'
import { CustomDateParserFormatter, CustomDatepickerI18n } from './modules/theme/datepicker/datepicker.component'
import { AuthService } from './services/auth.service'
import { GeneralSettingsService } from './services/general-settings.service'
import { InitService } from './services/init.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class AppComponent {

  constructor(translate: TranslateService,
              generalSettings: GeneralSettingsService,
              public _init: InitService,
              auth: AuthService) {
    translate.setDefaultLang('cs_CZ')
    translate.use('cs_CZ')

    auth.user.pipe(distinctUntilChanged()).subscribe(user => {
      if (user) {
        switch (user.preferredLocalization) {
          case PreferedLocalization.SK:
            translate.use('sk_SK')
            break
          case PreferedLocalization.EN:
            translate.use('en_EN')
            break
          case PreferedLocalization.BG:
            translate.use('bg_BG')
            break
          case PreferedLocalization.DE:
            translate.use('de_DE')
            break
          case PreferedLocalization.HU:
            translate.use('hu_HU')
            break
          default:
            translate.use('cs_CZ')
        }
      }
    })
  }
}
