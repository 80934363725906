import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { ListModel, PasswordChangeModel, SetPasswordModel, UserFilterModel, UserModel, UserRoleModel } from '../models'
import { PreferedLocalization } from '../models/preferedLocalization.enum'
import { InvitationModel, InvitationResult } from '../models/user.model'
import { AuthService } from './auth.service'

@Injectable()
export class UserService {

  public url: string
  public filter: BehaviorSubject<UserFilterModel> = new BehaviorSubject<UserFilterModel>(new UserFilterModel())

  constructor(private _http: HttpClient,
              private _auth: AuthService) {
    this.url = '/api/administration/User'
  }

  public getList(): Observable<ListModel<UserModel>> {
    return this._http.get(this.url, { params: this.filter.value.toBeFilter() })
      .pipe(
        map((res: ListModel<UserModel>) => {
          const list = new ListModel<UserModel>()
          list.total = res.total
          list.data = res.data.map((x) => new UserModel(x))
          return list
        }),
      )
  }

  public getDropdownList(filter: UserFilterModel): Observable<ListModel<UserModel>> {
    return this._http.get(this.url, { params: filter.toBeFilter() })
      .pipe(
        map((res: ListModel<UserModel>) => {
          const list = new ListModel<UserModel>()
          list.total = res.total
          list.data = res.data.map((x) => new UserModel(x))
          return list
        }),
      )
  }

  public getDetail(id: number): Observable<UserModel> {
    return this._http.get(this.url + '/' + id)
      .pipe(
        map((res: UserModel) => new UserModel(res)),
      )
  }

  public save(users: UserModel[]): Observable<UserModel> {
    return this._http.post(this.url, users)
      .pipe(
        map((res: UserModel) => new UserModel(res)),
      )
  }

  public update(user: UserModel): Observable<UserModel> {
    return this._http.put(this.url, user, { params: { id: user.id.toString() } })
      .pipe(
        map((res: UserModel) => {
          return new UserModel(res)
        }),
      )
  }

  public delete(user: UserModel): Observable<boolean> {
    return this._http.post(this.url + '/BlockUser', null, { params: { id: user.id.toString() } }).pipe(
      map(() => true),
    )
  }

  public restore(email: string, lang: string): Observable<boolean> {
    return this._http.get<boolean>(this.url + '/ForgotPassword', { params: { email: email, lang: lang } })
  }

  public setPassword(data: SetPasswordModel, newUser: boolean): Observable<boolean> {
    const url = newUser ? '/SetPassword' : '/RestorePassword'
    return this._http.post<boolean>(this.url + url, data)
  }

  public updatePassword(data: PasswordChangeModel): Observable<boolean> {
    return this._http.post<boolean>(this.url + '/ChangePassword', data)
  }

  public getRoles(filter): Observable<UserRoleModel[]> {
    return this._http.get<UserRoleModel[]>(this.url + '/RolesToFilter', { params: filter })
  }

  public invite(users: InvitationModel[]): Observable<InvitationResult[]> {
    return this._http.post<InvitationResult[]>(this.url + '/Invite', users)
  }

  public cancelInvitation(user: UserModel): Observable<UserModel> {
    return this._http.post(this.url + '/Uninvite', null, { params: { username: user.username } })
      .pipe(
        map((res: UserModel) => new UserModel(res)),
      )
  }

  public reinvite(user: UserModel): Observable<any> {
    return this._http.post(this.url + '/Reinvite', null, { params: { username: user.username } })
      .pipe(
        map((res: UserModel) => new UserModel(res)),
      )

  }

  public unblockUser(user: UserModel): Observable<UserModel> {
    return this._http.post(this.url + '/ActivateUser', null, { params: { id: user.id.toString() } })
      .pipe(
        map((res: UserModel) => new UserModel(res)),
      )
  }

  public getUserByRestoreCode(code: string): Observable<string> {
    return this._http.get<string>(this.url + '/GetUserByRestoreCode', { params: { restoreCode: code } })
  }

  public getUserByActivationCode(code: string): Observable<string> {
    return this._http.get<string>(this.url + '/GetUserByCode', { params: { activationCode: code } })
  }

  public changePreferedLocalization(preferedLocalization: PreferedLocalization): Observable<any> {
    return this._http.post(this.url + '/SetLocalization', null, { params: { localization: preferedLocalization.toString() } })
      .pipe(
        tap(() => this._auth.updateUserLanguage(preferedLocalization)),
      )
  }
}
