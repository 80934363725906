import { Component, Input, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter, takeWhile } from 'rxjs/internal/operators'
import { BaseComponent } from '../../base/base.component'

@Component({
  selector: 'app-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.scss'],
})
export class NavbarItemComponent extends BaseComponent implements OnInit {

  @Input('label') label: string
  @Input('link') link: string
  @Input('icon') icon: string
  @Input('submenu') submenu: boolean

  public isRouteActive: boolean
  public showSubmenu: boolean

  constructor(public _router: Router) {
    super()
  }

  ngOnInit() {
    this._router.events.pipe(
      takeWhile(() => this.alive),
      filter(e => e instanceof NavigationEnd),
    )
      .subscribe((event: NavigationEnd) => {
        this.isRouteActive = event.url.startsWith(this.link) || event.urlAfterRedirects.startsWith(this.link)
      })
  }


}
