import { isNullOrUndefined } from '../../helpers/util.helper'
import { ClassModel } from '../car.models'
import { FilterModel } from '../filter.model'
import { PriceListBaseItemModel } from './price-list-base-item.models'

export class PriceListReplacementCarModel extends PriceListBaseItemModel {
  description: string
  defaultNoOfDaysPerYear: number
  carClass: ClassModel

  constructor(data) {
    if (data) {
      super(data)
      this.description = data.description
      this.defaultNoOfDaysPerYear = data.defaultNoOfDaysPerYear
      this.carClass = new ClassModel(data.carClass)
    }
  }
}

export class PriceListReplacementCarFilter extends FilterModel {

  carClass: ClassModel

  constructor(data?: PriceListReplacementCarFilter) {
    if (data) {
      super(data)
      this.carClass = data.carClass
    } else {
      super()
      this.orderBy = 'Name'
      this.carClass = null
    }
  }

  get filterUsed(): boolean {
    return !!this.searchText || !!this.carClass
  }

  toBeFilter() {
    const filter = {
      page: this.page.toString(),
      pageSize: this.pageSize.toString(),
      orderBy: this.orderBy,
      orderDesc: this.orderDesc ? 'true' : null,
      searchText: this.searchText,
      carClass: this.carClass ? this.carClass.id.toString() : null,
    }
    Object.keys(filter).forEach((key) => (isNullOrUndefined(filter[key])) && delete filter[key])
    return filter
  }
}
