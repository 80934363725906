import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgSelectModule } from '@ng-select/ng-select'
import { TranslateModule } from '@ngx-translate/core'
import { ConfirmDeletionDirective } from '../../directives/confirm-deletion.directive'
import { FloatInputFormatterDirective } from '../../directives/float-input-formatter.directive'
import { NumbersOnlyDirective } from '../../directives/numbers-only.directive'
import { OnOutsideClickDirective } from '../../directives/on-outside-click.directive'
import { PermissionRestrictDirective } from '../../directives/restrict.directive'
import { AppCurrencyPipe } from '../../pipes/currency.pipe'
import { DatePipe } from '../../pipes/date.pipe'
import { AppInchPipe } from '../../pipes/inch.pipe'
import { AppMilesPipe } from '../../pipes/miles.pipe'
import { AppMilimeterPipe } from '../../pipes/milimeter.pipe'
import { AppMonthsPipe } from '../../pipes/months.pipe'
import { AppNumberPipe } from '../../pipes/number.pipe'
import { BackButtonComponent } from './back-button/back-button.component'
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component'
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component'
import { IconButtonComponent } from './icon-button/icon-button.component'
import { IconComponent } from './icon/icon.component'
import { LayoutComponent } from './layout/layout.component'
import { LoaderComponent } from './loader/loader.component'
import { MissingPermissionComponent } from './missing-permission/missing-permission.component'
import { NavbarItemComponent } from './navbar/navbar-item/navbar-item.component'
import { NavbarSubItemComponent } from './navbar/navbar-sub-item/navbar-sub-item.component'
import { NavbarComponent } from './navbar/navbar.component'
import { UserMenuComponent } from './navbar/user-menu/user-menu.component'
import { PaginationComponent } from './pagination/pagination.component'
import { TableEmptyComponent } from './table-empty/table-empty.component'
import { TableHeaderItemComponent } from './table-header-item/table-header-item.component'
import { TabComponent } from './tabs/tab/tab.component'
import { TabsComponent } from './tabs/tabs.component'

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        TranslateModule,
        FlexLayoutModule,
        NgbModule,
        NgSelectModule,
    ],
    declarations: [
        ConfirmDeletionDirective,
        FloatInputFormatterDirective,
        NumbersOnlyDirective,
        OnOutsideClickDirective,
        PermissionRestrictDirective,
        LayoutComponent,
        IconComponent,
        NavbarComponent,
        NavbarItemComponent,
        NavbarSubItemComponent,
        UserMenuComponent,
        BreadcrumbComponent,
        IconButtonComponent,
        PaginationComponent,
        AppCurrencyPipe,
        AppMonthsPipe,
        AppMilesPipe,
        AppNumberPipe,
        AppInchPipe,
        AppMilimeterPipe,
        DatePipe,
        TableHeaderItemComponent,
        TableEmptyComponent,
        TabsComponent,
        TabComponent,
        ConfirmModalComponent,
        BackButtonComponent,
        LoaderComponent,
        MissingPermissionComponent,
    ],
    exports: [
        ConfirmDeletionDirective,
        FloatInputFormatterDirective,
        NumbersOnlyDirective,
        OnOutsideClickDirective,
        PermissionRestrictDirective,
        LayoutComponent,
        IconComponent,
        BreadcrumbComponent,
        IconButtonComponent,
        PaginationComponent,
        AppCurrencyPipe,
        AppMonthsPipe,
        AppMilesPipe,
        AppNumberPipe,
        AppInchPipe,
        AppMilimeterPipe,
        DatePipe,
        TableHeaderItemComponent,
        TableEmptyComponent,
        TabsComponent,
        TabComponent,
        TranslateModule,
        BackButtonComponent,
        LoaderComponent,
    ]
})
export class ThemeModule {
}
